import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { BodySmall, LabelBold } from 'styles/typography';
import DeactivatedUserIcon from 'svg/DeactivatedUserIcon';
import SSOErrorIcon from 'svg/SSOErrorIcon';

import { ErrorState, ErrorStateKind } from './ErrorState';
import SimpleLandingPage from './SimpleLandingPage';

const EmailLink = styled.span`
  font-weight: var(--font-weight-bold);
  color: var(--primary-blue);
`;

const StyledBody = styled(BodySmall)`
  margin-top: 24px;
  width: 100%;
  text-align: center;
  padding: 0 24px;
`;

function DeactivatedAccount() {
  return (
    <>
      <DeactivatedUserIcon />
      <StyledBody>
        <LabelBold fontSize='14px'>Your account is currently deactivated.</LabelBold>
        <br />
        For help with reactivation, please reach out to <EmailLink>support@olio.health</EmailLink>
      </StyledBody>
    </>
  );
}

function AccountLinkError() {
  return (
    <>
      <SSOErrorIcon />
      <StyledBody>
        <LabelBold fontSize='14px'>Oh no! Looks like something went wrong linking your SSO account.</LabelBold>
        <br />
        <br />
        For help resolving this issue, please reach out to <EmailLink>support@olio.health</EmailLink>
      </StyledBody>
    </>
  );
}

const ERROR_CODE_COMPONENTS = {
  'user.inactive': <DeactivatedAccount />,
  'user.link-account': <AccountLinkError />,
  'patient-not-found': <ErrorState kind={ErrorStateKind.PatientNotFound} useSimpleLandingPage={false} />,
  'request-access': <ErrorState kind={ErrorStateKind.RequestAccess} useSimpleLandingPage={false} />,
};

export default function AuthError() {
  const [urlParams] = useSearchParams();
  const errorCode = urlParams.get('code') || '';
  const Component = ERROR_CODE_COMPONENTS[errorCode] || (
    <ErrorState kind={ErrorStateKind.SomethingWentWrong} useSimpleLandingPage={false} />
  );

  return <SimpleLandingPage>{Component}</SimpleLandingPage>;
}
