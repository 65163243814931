import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Dimensions, Metrics } from 'components/insights/constants';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { AnalyticsQueryParams } from 'components/insights/query/useAnalyticsInfiniteQuery';
import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import Card from '../../Card';
import ApiExport from '../ApiExport';
import { CategoryRow } from '../helpers/utils';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import TableContainer from '../TableContainer';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  requestParams: AnalyticsQueryParams;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimension: Dimensions;
};

const TotalDischargesDataTable = ({ data, loading, requestParams, rollups, selectedDimension }: Props) => {
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.currentSeries.flat(), [data?.dimensions]);
  const dischargesCountRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.ID_COUNT, 0);
  const dischargeCounts = data.metrics.getCurrentSeries(Metrics.ID_COUNT);
  const priorDischargeCounts = data.metrics.getPriorSeries(Metrics.ID_COUNT);

  const tableData = useMemo<CategoryRow[]>(() => {
    return categories.map((categoryName, i) => ({
      categoryName,
      discharges: {
        current: dischargeCounts[i],
        prior: priorDischargeCounts[i],
      },
    }));
  }, [categories, dischargeCounts, priorDischargeCounts]);

  const columnHelper = createColumnHelper<CategoryRow>();

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case Dimensions.PLAN_TYPE:
        return 'Plan Type';
      case Dimensions.EPISODE_TYPE:
        return 'Episode Type';
      case Dimensions.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      case Dimensions.REASON:
        return 'Disposition';
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columns = useMemo<ColumnDef<CategoryRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('discharges', {
        header: 'Discharges',
        cell: (data) => (
          <>
            <p>{data.getValue().current === null ? <>&mdash;</> : `${data.getValue().current}`}</p>{' '}
            <MetricDiffBadge
              currentValue={data.getValue().current}
              priorValue={data.getValue().prior}
              comparator={Comparator.GREATER_THAN}
              formatter={(val) => `${val.toFixed()}`}
            />
          </>
        ),
        footer: () => `${dischargesCountRollup ?? '—'} (Total)`,
      }),
    ],
    [columnHelper, dischargesCountRollup, getTableHeader]
  );

  const defaultSort = [
    {
      id: 'discharges',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>Total Discharges Overview</Card.Title>
        <ApiExport
          disabled={!tableData.length}
          show={!loading}
          params={requestParams}
          filename='total-discharges-export'
        />
      </TableTitleContainer>
      <DataTable columns={columns} data={tableData} defaultSortBy={defaultSort} loading={loading} />
    </TableContainer>
  );
};

export default TotalDischargesDataTable;
