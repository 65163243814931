import { ResponseRow } from './AnalyticsResponse';

export default class AnalyticsDimensionCollection {
  currentRows: ResponseRow[];
  priorRows: ResponseRow[];

  constructor(currentRows: ResponseRow[], priorRows: ResponseRow[]) {
    this.currentRows = currentRows;
    this.priorRows = priorRows;
  }

  get currentSeries() {
    return this.currentRows.map((row) => row.formattedDimensionValues);
  }

  get priorSeries() {
    return this.priorRows.map((row) => row.formattedDimensionValues);
  }

  get series() {
    // This returns the unique series of current & prior dimension values
    const uniqueDimensionMap: { [key: string]: string[] } = {};
    const allDimensionSeries = Array.from([...this.currentSeries, ...this.priorSeries]);
    allDimensionSeries.forEach((dimensionValues) => {
      const stringifiedDimensionValues = dimensionValues.toString();

      if (!uniqueDimensionMap[stringifiedDimensionValues]) {
        uniqueDimensionMap[stringifiedDimensionValues] = dimensionValues;
      } else {
        return;
      }
    });

    return Object.values(uniqueDimensionMap);
  }
}
