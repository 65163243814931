import { useMemo } from 'react';

import { Dimensions, Metrics } from 'components/insights/constants';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import Histogram from 'components/shared/charts/Histogram';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import ChartContainer from '../ChartContainer';
import { generateExtendedChartConfig } from '../helpers/alosUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDetailHeader from '../MetricDetailHeader';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import ViewBySelect from '../ViewBySelect';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  onSelectedDimensionChange: (selectedDimension: Dimensions) => void;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimension: Dimensions;
};

const AverageLengthOfStayChart = (props: Props) => {
  const { data, loading, onSelectedDimensionChange, rollups, selectedDimension } = props;
  const isMobile = useIsMobile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.series.flat(), [data.dimensions]);

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories, data, rollups }),
    [categories, data, rollups]
  );

  const lengthOfStayAverageRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.LENGTH_OF_STAY_AVERAGE, 0);
  const priorLengthOfStayAverageRollup = rollups.overall.metrics.getPriorDataPoint(Metrics.LENGTH_OF_STAY_AVERAGE, 0);

  const lengthOfStayAverages = data.metrics.getCurrentSeries(Metrics.LENGTH_OF_STAY_AVERAGE);

  return (
    <MetricDetailContainer
      loading={loading}
      hasData={!!lengthOfStayAverages?.length}
      header={
        <>
          <MetricDetailHeader
            label='Average Length of Stay'
            loading={loading}
            hasData={!!lengthOfStayAverages?.length}
            value={
              <>
                {`${lengthOfStayAverageRollup?.toFixed(1) ?? '—'} days`}
                <MetricDiffBadge
                  currentValue={lengthOfStayAverageRollup}
                  priorValue={priorLengthOfStayAverageRollup}
                  comparator={Comparator.LESS_THAN}
                  formatter={(val) => `${val.toFixed(1)}`}
                />
              </>
            }
          />
          {!isMobile && (
            <ViewBySelect
              selectedDimension={selectedDimension}
              groupType={selectedGroupType}
              additionalOptions={[{ label: 'Disposition', value: Dimensions.REASON }]}
              onChange={(selectedOption) => {
                onSelectedDimensionChange(selectedOption.value);
              }}></ViewBySelect>
          )}
        </>
      }>
      <ChartContainer>
        <Histogram config={chartConfig} />
      </ChartContainer>
    </MetricDetailContainer>
  );
};

export default AverageLengthOfStayChart;
