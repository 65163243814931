import { useMemo } from 'react';

import { Dimensions, Metrics, Routes, Sources } from 'components/insights/constants';
import useAnalytics from 'components/insights/query/useAnalytics';
import AreaChart from 'components/shared/charts/AreaChart';
import { formatDateLabel, getFormattedDatesInRange } from 'components/shared/charts/helpers';
import { useInsightsStore } from 'stores/insightsStore';

import MetricTile from './MetricTile';

export default function TotalDischargesTile() {
  const filters = useInsightsStore((state) => state.filters);

  const queryParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DISCHARGES,
      dimensions: [Dimensions.DATE],
      metrics: [Metrics.ID_COUNT],
      rollups: true,
    }),
    []
  );

  const { customRef, loading, data, rollups } = useAnalytics(queryParams, { onIntersecting: true });

  const dischargesCountRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.ID_COUNT, 0);
  const dischargesCount = data.metrics.getCurrentSeries(Metrics.ID_COUNT);
  const dates = getFormattedDatesInRange(filters.startDate as Date, filters.endDate as Date).reduce(
    (acc, formattedDate) => {
      acc[formattedDate] = 0;
      return acc;
    },
    {}
  );

  data.dimensions.currentSeries.flat().forEach((row: string, idx: number) => {
    dates[row] = dischargesCount[idx];
  });

  const categories = Object.keys(dates).map(formatDateLabel);
  const values = Object.values(dates);

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsTotalDischarges'
      data-cy='insightsTotalDischarges'
      label='Total Discharges'
      showValue
      loading={loading}
      hasData={dischargesCountRollup != null && values.some((value) => value)}
      value={`${dischargesCountRollup}`}
      navigateTo={Routes.TOTAL_DISCHARGES}>
      <AreaChart
        formatTooltipSupertext={(x) => `${x}`}
        formatTooltipSubtext={(y) => `Total Discharges: ${y}`}
        categories={categories}
        values={values as number[]}
      />
    </MetricTile>
  );
}
