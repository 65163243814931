import { SeriesOptionsType } from 'highcharts';
import { TooltipFormatterContextObject } from 'highcharts';

import colors from 'styles/theme/colors';

import { ChartSeries } from './utils';

// Calculates the scrollable width of the chart based on the number of data points
const calculateWidth = (pointWidth: number, dataLength: number) => {
  return (pointWidth + pointWidth * 1.5) * dataLength;
};

export const generateExtendedChartConfig = ({ categories, series }: ChartSeries): Highcharts.Options => {
  return {
    chart: {
      scrollablePlotArea: {
        minWidth: calculateWidth(40, series[0]?.length),
        scrollPositionX: 0,
      },
    },
    legend: {
      enabled: true,
      padding: 0,
      itemMarginBottom: 10,
      itemMarginTop: 16,
      align: 'center',
      symbolRadius: 2,
      x: 0,
      verticalAlign: 'bottom',
      y: 0,
      itemStyle: {
        fontSize: '12px',
      },
    },
    xAxis: {
      categories: categories,
      lineWidth: 1,
      lineColor: colors.black25,
      labels: {
        style: {
          fontSize: '10px',
          color: colors.black,
        },
      },
    },
    scrollbar: {
      enabled: true,
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineColor: colors.black10,
      plotLines: [
        {
          color: '#F06532',
          width: 2,
          value: 100,
          dashStyle: 'ShortDash',
        },
      ],
    },
    plotOptions: {
      column: {
        maxPointWidth: 40,
        events: { legendItemClick: () => false, mouseOver: () => false },
        dataLabels: {
          enabled: true,
          formatter: function (this: TooltipFormatterContextObject): string {
            return `<span style="font-size:10px;font-weight:normal;">${this.point.label}</span>`;
          },
        },
      },
      line: {
        enableMouseTracking: false,
        events: { legendItemClick: () => false, mouseOver: () => false },
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        type: 'column',
        data: series[0]?.map((val) => ({ y: val ?? 0, label: val == null ? 'N/A' : val.toFixed() })),
        name: 'Discharges',
        states: {
          inactive: {
            opacity: 0.05,
          },
        },
      },
      ...(series[1]?.length > 0
        ? [
            {
              type: 'column',
              // Converts nulls to 0 so the chart can render them, but displays 'N/A' in the tooltip
              data: series[1].map((val) => ({ y: val ?? 0, label: val == null ? 'N/A' : val.toString() })),
              name: 'Prior Discharges',
              color: '#C1CBFA',
            } as SeriesOptionsType,
          ]
        : []),
    ],
  };
};
