import { camelCase } from 'lodash';

import { SortOrder } from 'components/insights/constants';
import { PortfolioTab } from 'components/portfolio/Portfolio';
import {
  ADDITIONAL_FILTERS,
  CASE_MANAGER_DROPDOWN,
  COMANAGEMENT_TEAM,
  createProviderDropdown,
  EPISODE_TYPE_DROPDOWN,
  getComanagementTeamAcuteFilterDropdowns,
  PLAN_TYPE_DROPDOWN,
  STATUS_OUT_OF_DATE_CHECKBOX,
  UTILIZATION_MANAGER_DROPDOWN,
} from 'constants/filterConfigs';
import { PatientState } from 'constants/filterKeysConstants';
import { ClientType } from 'models/Client';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import FilterValueBoolean from 'models/filterValues/FilterValueBoolean';
import GroupType from 'models/GroupType';
import { RehabStateApiName } from 'models/RehabState';
import { PortfolioFilterState } from 'stores/portfolioStore';

const getAdditionalFilterDropdowns = (
  caseManagerEnabled: boolean,
  utilizationManagerEnabled: boolean,
  selectedTab: PortfolioTab
) => {
  return [
    PLAN_TYPE_DROPDOWN,
    EPISODE_TYPE_DROPDOWN,
    ...(utilizationManagerEnabled ? [UTILIZATION_MANAGER_DROPDOWN] : []),
    ...(caseManagerEnabled ? [CASE_MANAGER_DROPDOWN] : []),
    ...(selectedTab.patientState !== PatientState.CONTINUED ? [STATUS_OUT_OF_DATE_CHECKBOX] : []),
  ];
};

export const getFilterSections = (
  selectedTab: PortfolioTab,
  actingClientType: ClientType,
  caseManagerEnabled: boolean,
  utilizationManagerEnabled: boolean
) => {
  return [
    {
      title: COMANAGEMENT_TEAM,
      filters: [
        ![PatientState.CONTINUED, PatientState.LATEST].includes(selectedTab.patientState as PatientState) &&
          createProviderDropdown(
            new GroupType({
              id: selectedTab?.groupType ?? '',
              displayName: selectedTab?.displayName ?? '',
              apiName: selectedTab?.value ?? '',
            }),
            {
              include: 'groupType',
            }
          ),
        ...getComanagementTeamAcuteFilterDropdowns(actingClientType),
      ],
    },
    {
      title: ADDITIONAL_FILTERS,
      filters: getAdditionalFilterDropdowns(caseManagerEnabled, utilizationManagerEnabled, selectedTab),
    },
  ];
};

export function filtersForSelectedTab(filters: PortfolioFilterState, tab: PortfolioTab): PortfolioFilterState {
  if (!tab) return {};

  return Object.entries(filters).reduce((acc, [filterKey, value]) => {
    if (value instanceof FilterValueArray && !value.length) return acc;

    if (value[0]?.type !== 'Group::RehabFacility' && !(value instanceof FilterValueBoolean)) {
      acc[filterKey] = value;
      return acc;
    }

    const filterKeyIsCurrentProviderTab = filterKey === camelCase(tab?.value ?? '');

    switch (tab?.patientState) {
      case PatientState.CURRENT:
      case undefined:
        if (filterKeyIsCurrentProviderTab || value instanceof FilterValueBoolean) {
          acc[filterKey] = value;
        }

        return acc;
      case PatientState.LATEST:
      case PatientState.CONTINUED:
        return acc;
      default:
        return acc;
    }
  }, {});
}

type SortOption = {
  attributeName: string;
  direction: SortOrder;
  rehabStateApiName: RehabStateApiName;
  label: string;
  visible: boolean;
};

export const portfolioLaneSortingOptions = (
  rehabStateApiName: RehabStateApiName,
  showSortByProjectedDischargeDate: boolean
): SortOption[] =>
  [
    {
      attributeName: 'lengthOfStay',
      direction: SortOrder.DESC,
      rehabStateApiName,
      label: 'Length of Stay (Highest)',
      visible: rehabStateApiName !== RehabStateApiName.Queue,
    },
    {
      attributeName: 'lengthOfStay',
      direction: SortOrder.ASC,
      rehabStateApiName,
      label: 'Length of Stay (Lowest)',
      visible: rehabStateApiName !== RehabStateApiName.Queue,
    },
    {
      attributeName: 'patientName',
      direction: SortOrder.ASC,
      rehabStateApiName,
      label: 'Patient Name (A-Z)',
      visible: true,
    },
    {
      attributeName: 'patientName',
      direction: SortOrder.DESC,
      rehabStateApiName,
      label: 'Patient Name (Z-A)',
      visible: true,
    },
    {
      attributeName: 'projectedDischargeDate',
      direction: SortOrder.ASC,
      rehabStateApiName,
      label: 'Upcoming Projected Discharge Date',
      visible: showSortByProjectedDischargeDate,
    },
  ].filter(({ visible }) => visible);
