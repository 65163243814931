import GroupType, { GroupTypeOptions } from 'models/GroupType';

export interface DischargeReasonOptions {
  id: string;
  displayName: string;
  isReadmission: boolean;
  archived: boolean;
  dischargedToGroupTypeId: string;
  dischargedToGroupType: Partial<GroupTypeOptions> | null;
  infoMessage: string | null;
  warningMessage: string | null;
}

function getDefaults(): DischargeReasonOptions {
  return {
    id: '',
    displayName: '',
    isReadmission: false,
    archived: false,
    dischargedToGroupTypeId: '',
    dischargedToGroupType: null,
    infoMessage: null,
    warningMessage: null,
  };
}

export default class DischargeReason {
  id: string;
  displayName: string;
  isReadmission: boolean;
  archived: boolean;
  dischargedToGroupTypeId: string;
  dischargedToGroupType: GroupType | null;
  groupTypeDisplayNames?: string;
  infoMessage: string | null;
  warningMessage: string | null;

  constructor(options: Partial<DischargeReasonOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.displayName = opts.displayName;
    this.isReadmission = opts.isReadmission;
    this.archived = opts.archived;
    this.dischargedToGroupTypeId = opts.dischargedToGroupTypeId;
    this.infoMessage = opts.infoMessage;
    this.warningMessage = opts.warningMessage;

    // included joins
    this.dischargedToGroupType = opts.dischargedToGroupType ? new GroupType(opts.dischargedToGroupType) : null;
  }

  // This getter is used so that the `getChipLabel` function in the `FilterChips` component
  // does not have to do any type gymnastics
  get name() {
    return this.displayName;
  }
}
