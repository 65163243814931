import Classification, { ClassificationOptions } from './Classification';
import ClientGroupType, { ClientGroupTypeOptions } from './ClientGroupType';
import GroupType, { GroupTypeOptions } from './GroupType';

export enum ClientType {
  ADMIN = 'Admin',
  AFFILIATE = 'Affiliate',
  HEALTH_SYSTEM = 'Health System',
  INPATIENT_POST_ACUTE_CARE = 'Inpatient Post Acute Care',
  OUTPATIENT_POST_ACUTE_CARE = 'Outpatient Post Acute Care',
  PAYOR = 'Payor',
  PHYSICIAN_GROUP = 'Physician Group',
}

export enum OWNER_CLIENT_TYPES {
  HEALTH_SYSTEM = 'Health System',
  PAYOR = 'Payor',
  PHYSICIAN_GROUP = 'Physician Group',
}

export type ACUTE_CLIENT_TYPES = ClientType.AFFILIATE | OWNER_CLIENT_TYPES;

export interface ClientOptions {
  id: string;
  children: Partial<ClientOptions>[];
  clientType: ClientType;
  clientGroupTypes: Partial<ClientGroupTypeOptions>[];
  collaboratorIds: string[];
  episodeClassifications: Partial<ClassificationOptions>[];
  enabledProviderTypes: Partial<GroupTypeOptions>[];
  groupTypeDisplayNames: string[];
  groupTypes: Partial<GroupTypeOptions>[];
  leaf: boolean;
  leafDescendants: Partial<ClientOptions>[];
  name: string;
  parent: Partial<ClientOptions> | null;
  parentId: string | null;
  planTypeClassifications: Partial<ClassificationOptions>[];
  ssoEnabled: boolean;
  enabledFlags: string[];
}

function getDefaults(): ClientOptions {
  return {
    id: '',
    children: [],
    clientType: '' as ClientType,
    clientGroupTypes: [],
    collaboratorIds: [],
    episodeClassifications: [],
    enabledProviderTypes: [],
    groupTypeDisplayNames: [],
    groupTypes: [],
    leaf: true,
    leafDescendants: [],
    name: '',
    parent: null,
    parentId: null,
    planTypeClassifications: [],
    ssoEnabled: false,
    enabledFlags: [],
  };
}
/**
 *
 * @class Client
 * @classdesc Represents a client in the system
 * @property {string} id - The client's id
 * @property {Client[]} children - The client's children
 * @property {ClientType} clientType - The client's type
 * @property {ClientGroupType[]} clientGroupTypes - The client's group types
 * @property {string[]} collaboratorIds - The client's collaborator ids
 * @property {Classification[]} episodeClassifications - The client's episode classifications
 * @property {string[]} groupTypeDisplayNames - The client's group type display names
 * @property {GroupType[]} groupTypes - The client's group types
 * @property {boolean} leaf - Whether the client is a leaf
 * @property {Client[]} leafDescendants - The client's leaf descendants
 * @property {string} name - The client's name
 * @property {Client} parent - The client's parent
 * @property {string} parentId - The client's parent id
 * @property {Classification[]} planTypeClassifications - The client's plan type classifications
 * @property {boolean} ssoEnabled - Whether the client has SSO enabled
 * @property {string[]} enabledFlags - The client's enabled flags
 * @method {boolean} isAcute - Whether the client is acute
 * @method {boolean} isAdmin - Whether the client is an admin
 * @method {boolean} isAffiliate - Whether the client is an affiliate
 * @method {boolean} isHealthSystem - Whether the client is a health system
 * @method {boolean} isInpatientPostAcute - Whether the client is inpatient post acute
 * @method {boolean} isOutpatientPostAcute - Whether the client is outpatient post acute
 * @method {boolean} isOwningClientType - Whether the client is an owning client type
 * @method {boolean} isPayor - Whether the client is a payor
 * @method {boolean} isParent - Whether the client is a parent
 * @method {boolean} isPhysicianGroup - Whether the client is a physician group
 * @method {string[]} leafDescendantIds - The client's leaf descendant ids
 * @method {GroupType[]} providerGroupTypes - The client's provider group types
 * @method {string[]} groupTypeIds - The client's group type ids
 * @method {object} toFormValues - The client's form values
 * @param {Partial<ClientOptions>} options - The client's options
 * @returns {Client} - A new client
 */
export default class Client {
  id: string;
  children: Client[];
  clientType: ClientType;
  clientGroupTypes: ClientGroupType[];
  collaboratorIds: string[];
  episodeClassifications: Classification[];
  enabledProviderTypes: GroupType[];
  groupTypeDisplayNames: string[];
  groupTypes: GroupType[];
  leaf: boolean;
  leafDescendants: Client[];
  name: string;
  parent: Client | null;
  parentId: string | null;
  planTypeClassifications: Classification[];
  ssoEnabled: boolean;
  enabledFlags: string[];

  constructor(options: Partial<ClientOptions> = {}) {
    const opts = { ...getDefaults(), ...options };
    this.id = opts.id;
    this.children = opts.children.map((child) => new Client(child));
    this.clientType = opts.clientType;
    this.collaboratorIds = opts.collaboratorIds;
    this.clientGroupTypes = opts.clientGroupTypes.map((cgt) => new ClientGroupType(cgt));
    this.episodeClassifications = opts.episodeClassifications.map((ec) => new Classification(ec));
    this.enabledProviderTypes = opts.enabledProviderTypes.map((gt) => new GroupType(gt));
    this.groupTypeDisplayNames = opts.groupTypeDisplayNames;
    this.groupTypes = opts.groupTypes.map((gt) => new GroupType(gt));
    this.leaf = opts.leaf;
    this.leafDescendants = opts.leafDescendants.map((child) => new Client(child));
    this.name = opts.name;
    this.parent = opts.parent ? new Client(opts.parent) : null;
    this.parentId = opts.parentId;
    this.planTypeClassifications = opts.planTypeClassifications.map((p) => new Classification(p));
    this.ssoEnabled = opts.ssoEnabled;
    this.enabledFlags = opts.enabledFlags;
  }

  get isAcute() {
    return [ClientType.AFFILIATE, ClientType.HEALTH_SYSTEM, ClientType.PAYOR, ClientType.PHYSICIAN_GROUP].includes(
      this.clientType
    );
  }

  get isPostAcute() {
    return [ClientType.INPATIENT_POST_ACUTE_CARE, ClientType.OUTPATIENT_POST_ACUTE_CARE].includes(this.clientType);
  }

  get isAdmin() {
    return this.clientType === ClientType.ADMIN;
  }
  get isAffiliate() {
    return this.clientType === ClientType.AFFILIATE;
  }
  get isHealthSystem() {
    return this.clientType === ClientType.HEALTH_SYSTEM;
  }
  get isInpatientPostAcute() {
    return this.clientType === ClientType.INPATIENT_POST_ACUTE_CARE;
  }
  get isOutpatientPostAcute() {
    return this.clientType === ClientType.OUTPATIENT_POST_ACUTE_CARE;
  }
  get isOwningClientType() {
    return [ClientType.HEALTH_SYSTEM, ClientType.PAYOR, ClientType.PHYSICIAN_GROUP].includes(this.clientType);
  }
  get isPayor() {
    return this.clientType === ClientType.PAYOR;
  }
  get isParent() {
    return !this.leaf;
  }
  get isPhysicianGroup() {
    return this.clientType === ClientType.PHYSICIAN_GROUP;
  }
  get leafDescendantIds() {
    return this.leafDescendants.map((leaf) => leaf.id);
  }
  get providerGroupTypes() {
    return this.groupTypes.filter((groupType) => groupType.type.apiName === 'provider');
  }
  get groupTypeIds() {
    return this.groupTypes.map((groupType) => groupType.id);
  }

  hasFlag(flag: string) {
    return this.enabledFlags.includes(flag);
  }

  toFormValues() {
    return {
      ...this,
      clientType: this.clientType ? { name: this.clientType, kind: this.clientType } : null,
    };
  }

  configForGroupType(groupTypeApiNameOrId: string) {
    return this.clientGroupTypes.find((clientGroupType) =>
      [clientGroupType.groupType?.apiName, clientGroupType.groupTypeId].includes(groupTypeApiNameOrId)
    )?.config;
  }
}
