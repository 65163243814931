import { useMemo } from 'react';

import { Metrics, Routes, Sources } from 'components/insights/constants';
import useAnalytics from 'components/insights/query/useAnalytics';
import OlioSolidGauge from 'components/shared/charts/OlioSolidGauge';

import MetricTile from './MetricTile';

export default function OlioEngagementScoreTile() {
  const queryParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DAYS,
      metrics: [Metrics.OLIO_ENGAGEMENT_SCORE],
    }),
    []
  );

  const { customRef, loading, data } = useAnalytics(queryParams, { onIntersecting: true });

  const olioEngagementScore = data.metrics.getCurrentSeries(Metrics.OLIO_ENGAGEMENT_SCORE)[0];

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsOES'
      data-cy='insightsOES'
      label='Olio Engagement Score (OES)'
      tooltipText='Measurement of engagement activity in Olio'
      showValue={false}
      loading={loading}
      hasData={olioEngagementScore != null}
      navigateTo={Routes.OLIO_ENGAGEMENT_SCORE}>
      <OlioSolidGauge value={olioEngagementScore} label={`${olioEngagementScore}%`} subLabel='OES Goal 100%' />
    </MetricTile>
  );
}
