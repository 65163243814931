import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { DefaultError, InfiniteData, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { useProfile } from 'context/profile';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import usePrevious from 'hooks/usePrevious';
import isDeepEqual from 'lib/isDeepEqual';
import { Analytics, retrieveAnalytics as _retrieveAnalytics } from 'services/api/insights/analytics';
import { getAnalyticsFilters, useInsightsStore } from 'stores/insightsStore';

import AnalyticsResponse from './AnalyticsResponse';
import useAnalyticsInfiniteQuery, { AnalyticsQueryParams } from './useAnalyticsInfiniteQuery';

type QueryResult = Awaited<ReturnType<typeof _retrieveAnalytics>>;

type DataFetchConfig = {
  onIntersecting?: boolean;
};

const defaultQueryOptions = {
  getNextPageParam: () => null,
  initialPageParam: null,
};

export default function useAnalytics(
  queryParams: AnalyticsQueryParams,
  config: DataFetchConfig = {},
  queryOptions: Omit<
    UseInfiniteQueryOptions<QueryResult, DefaultError, InfiniteData<Analytics, Analytics>>,
    'queryKey' | 'queryFn'
  > = defaultQueryOptions
) {
  const { onIntersecting = false } = config;

  const { pathname } = useLocation();
  const isInsightsDashboard = !!pathname.match(/\/insights$/);

  const { profile } = useProfile();
  const queryClient = useQueryClient();
  const comparePriorPeriod = useInsightsStore((state) => state.comparePriorPeriod);
  const analyticsFilters = useInsightsStore((state) => getAnalyticsFilters(state, profile));
  const filters = useInsightsStore((state) => state.filters);
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const previousFilters = usePrevious(filters);
  const previousSelectedGroupType = usePrevious(selectedGroupType);

  const isDataCached = queryClient.getQueryData(['analytics', queryParams, analyticsFilters]);

  const filtersChanged =
    !isDeepEqual(previousFilters, filters) || !isDeepEqual(previousSelectedGroupType, selectedGroupType);

  const {
    isIntersecting: hasIntersected,
    setIsIntersecting: setHasIntersected,
    customRef,
  } = useIntersectionObserver({
    initialState: !onIntersecting,
    once: true,
    threshold: 0.2,
  });

  const query = useAnalyticsInfiniteQuery(
    queryParams,
    // (1) Don't let the query fire immediately when filters change - let the useEffect block below
    // handle resetting the intersection observer, which will then trigger the query if/when the
    // element is actually in view.
    // (2) Don't refetch data when toggling off Compare Prior Period if drill-in page already has
    // cached values for selected filters.
    // Changing filters, navigating away, or refreshing will always refetch, at least in the background.
    {
      ...queryOptions,
      enabled:
        !filtersChanged &&
        (!onIntersecting || hasIntersected) &&
        (isInsightsDashboard || comparePriorPeriod || !isDataCached),
    }
  );

  const response = new AnalyticsResponse(query.data, comparePriorPeriod);

  useEffect(() => {
    if (filtersChanged) {
      setHasIntersected(false);
    }
  }, [filtersChanged, setHasIntersected]);

  const data = response.data;
  const rollups = response.rollups;

  return { customRef, loading: query.isPending || query.isRefetching, query, data, rollups };
}
