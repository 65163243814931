import { useMemo } from 'react';
import styled from 'styled-components';

import { Metrics, Routes, Sources } from 'components/insights/constants';
import MetricTile from 'components/insights/layout/tiles/MetricTile';
import useAnalytics from 'components/insights/query/useAnalytics';
import MetricValue from 'components/shared/charts/MetricValue';
import NoteIconOutline from 'svg/NoteIconOutline';
import PatientsIcon from 'svg/PatientsIcon';

export default function ActivitiesPerPatientPerWeekTile() {
  const queryParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DAYS,
      metrics: [
        Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK,
        Metrics.LOCATION_EPISODE_ID_COUNT,
        Metrics.OWNER_ACTIVITIES_SUM,
      ],
    }),
    []
  );

  const { customRef, loading, data } = useAnalytics(queryParams, { onIntersecting: true });

  const activitiesPerPatientPerWeek = data.metrics.getCurrentSeries(Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK)[0];
  const locationEpisodeCount = data.metrics.getCurrentSeries(Metrics.LOCATION_EPISODE_ID_COUNT)[0];
  const ownerActivitiesSum = data.metrics.getCurrentSeries(Metrics.OWNER_ACTIVITIES_SUM)[0];

  return (
    <MetricTile
      ref={customRef}
      showValue={true}
      loading={loading}
      data-gtm-id='activitiesPerPatientPerWeek'
      data-cy='activitiesPerPatientPerWeek'
      label='Activities Per Patient Per Week'
      value={`${activitiesPerPatientPerWeek?.toFixed(1) || 0}`}
      hasData={(locationEpisodeCount || 0) > 0}
      navigateTo={Routes.ACTIVITIES_PER_PATIENT_PER_WEEK}>
      <MetricWrapper>
        <MetricValue icon={NoteIconOutline} label='Activities' value={ownerActivitiesSum || 0} />
        <MetricValue icon={PatientsIcon} label='Patients' value={locationEpisodeCount || 0} />
      </MetricWrapper>
    </MetricTile>
  );
}

export const MetricWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 100%;
  width: 100%;
`;
