import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Dimensions, Metrics } from 'components/insights/constants';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { AnalyticsQueryParams } from 'components/insights/query/useAnalyticsInfiniteQuery';
import DataTable from 'components/shared/DataTable';
import GroupType from 'models/GroupType';
import { LabelBold } from 'styles/typography';

import Card from '../../Card';
import ApiExport from '../ApiExport';
import { CategoryRow } from '../helpers/utils';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import TableContainer from '../TableContainer';

export type ReadmissionRateTableRow = CategoryRow & {
  readmissions: number | null;
  readmissionRate: { current: number | null; prior: number | null };
};

type ReadmissionRateDataTableProps = {
  data: AnalyticsResponseData;
  loading: boolean;
  requestParams: AnalyticsQueryParams;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimension: Dimensions;
  selectedGroupType: GroupType;
};

const ReadmissionRateDataTable = (props: ReadmissionRateDataTableProps) => {
  const { data, loading, requestParams, rollups, selectedDimension, selectedGroupType } = props;

  const categories = useMemo(() => data.dimensions.currentSeries.flat(), [data.dimensions]);

  const dischargesTotal = useMemo(
    () => rollups.overall.metrics.getCurrentDataPoint(Metrics.ID_COUNT, 0),
    [rollups.overall.metrics]
  );
  const readmissionsTotal = useMemo(
    () => rollups.overall.metrics.getCurrentDataPoint(Metrics.READMISSIONS_SUM, 0),
    [rollups.overall.metrics]
  );
  const readmissionRate = useMemo(
    () => rollups.overall.metrics.getCurrentDataPoint(Metrics.READMISSION_RATE, 0),
    [rollups.overall.metrics]
  );

  const dischargeCounts = useMemo(() => data.metrics.getCurrentSeries(Metrics.ID_COUNT), [data.metrics]);
  const readmissionsSums = useMemo(() => data.metrics.getCurrentSeries(Metrics.READMISSIONS_SUM), [data.metrics]);
  const readmissionRates = useMemo(() => data.metrics.getCurrentSeries(Metrics.READMISSION_RATE), [data.metrics]);
  const priorReadmissionRates = useMemo(() => data.metrics.getPriorSeries(Metrics.READMISSION_RATE), [data.metrics]);

  const tableData = useMemo<ReadmissionRateTableRow[]>(() => {
    return categories.map((categoryName, i) => ({
      categoryName,
      readmissions: readmissionsSums[i],
      discharges: dischargeCounts[i],
      readmissionRate: {
        current: readmissionRates[i],
        prior: priorReadmissionRates[i],
      },
    }));
  }, [categories, dischargeCounts, readmissionsSums, readmissionRates, priorReadmissionRates]);

  const firstColumnHeader = useMemo(() => {
    switch (selectedDimension) {
      case Dimensions.PLAN_TYPE:
        return 'Plan Type';
      case Dimensions.EPISODE_TYPE:
        return 'Episode Type';
      case Dimensions.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      default:
        return selectedGroupType?.displayName || '';
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columnHelper = useMemo(() => createColumnHelper<ReadmissionRateTableRow>(), []);
  const columns = useMemo<ColumnDef<ReadmissionRateTableRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: firstColumnHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('readmissions', {
        header: 'Readmissions',
        cell: (data) => data.getValue() ?? <>&mdash;</>,
        footer: () => `${readmissionsTotal ?? '—'} (Total)`,
      }),
      columnHelper.accessor('discharges', {
        header: 'Discharges',
        cell: (data) => data.getValue() ?? <>&mdash;</>,
        footer: () => `${dischargesTotal ?? '—'} (Total)`,
      }),
      columnHelper.accessor('readmissionRate', {
        header: 'Readmission Rate',
        cell: (data) => {
          return (
            <>
              <p>{data.getValue().current == null ? <>&mdash;</> : `${data.getValue().current}%`}</p>{' '}
              <MetricDiffBadge
                currentValue={data.getValue().current}
                priorValue={data.getValue().prior}
                comparator={Comparator.LESS_THAN}
                formatter={(val) => `${val.toFixed(1)}%`}
              />
            </>
          );
        },
        footer: () => `${readmissionRate?.toFixed(1) ?? '—'}% (Avg)`,
      }),
    ],
    [columnHelper, dischargesTotal, firstColumnHeader, readmissionRate, readmissionsTotal]
  );

  const desktopOnlyColumns = ['readmissions', 'discharges'];

  const defaultSort = [
    {
      id: 'readmissionRate',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>Readmission Rate Overview</Card.Title>
        <ApiExport
          disabled={!tableData.length}
          show={!loading}
          params={requestParams}
          filename='readmission-rate-export'
        />
      </TableTitleContainer>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        loading={loading}
      />
    </TableContainer>
  );
};

export default ReadmissionRateDataTable;
