import AnalyticsDimensionCollection from './AnalyticsDimensionCollection';
import AnalyticsResponse, { ResponseRow } from './AnalyticsResponse';

export default class AnalyticsMetricCollection {
  analyticsResponse: AnalyticsResponse;
  dimensionCollection: AnalyticsDimensionCollection;
  currentRows: ResponseRow[];
  priorRows: ResponseRow[];

  constructor(
    analyticsResponse: AnalyticsResponse,
    dimensionCollection: AnalyticsDimensionCollection,
    currentRows: ResponseRow[],
    priorRows: ResponseRow[]
  ) {
    this.analyticsResponse = analyticsResponse;
    this.dimensionCollection = dimensionCollection;
    this.currentRows = currentRows;
    this.priorRows = priorRows;
  }

  get series() {
    return this.analyticsResponse.metricValueGatherer(
      this.dimensionCollection.series,
      this.currentRows,
      this.priorRows
    );
  }

  getSeriesByIndex(metricIndex: number) {
    return this.series?.[metricIndex] || new Array<Array<number | null>>([], []);
  }

  private getSeriesByMetricName(metricName: string) {
    return this.getSeriesByIndex(this.analyticsResponse.findMetricIndex(metricName));
  }

  getSeries(metricName: string) {
    return this.getSeriesByMetricName(metricName);
  }

  getCurrentSeries(metricName: string) {
    return this.getSeriesByMetricName(metricName)[0];
  }

  getCurrentSeriesByIndex(metricIndex: number) {
    return this.getSeriesByIndex(metricIndex)[0];
  }

  getCurrentDataPoint(metricName: string, dataPointIndex: number) {
    return this.getSeriesByMetricName(metricName)[0][dataPointIndex];
  }

  getCurrentDataPointByIndex(metricIndex: number, dataPointIndex: number) {
    return this.getSeriesByIndex(metricIndex)[0][dataPointIndex];
  }

  getPriorSeries(metricName: string) {
    return this.getSeriesByMetricName(metricName)[1];
  }

  getPriorSeriesByIndex(metricIndex: number) {
    return this.getSeriesByIndex(metricIndex)[1];
  }

  getPriorDataPoint(metricName: string, dataPointIndex: number) {
    return this.getSeriesByMetricName(metricName)[1][dataPointIndex];
  }

  getPriorDataPointByIndex(metricIndex: number, dataPointIndex: number) {
    return this.getSeriesByIndex(metricIndex)[1][dataPointIndex];
  }
}
