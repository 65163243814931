import styled from 'styled-components';

import { Blurb } from 'components/Containers';
import { Label } from 'styles/typography';

export const ModifiedBlurb = styled(Blurb)`
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: ${({ color, $border }) => ($border ? `4px solid ${color}` : 'none')};
`;

export const Separator = styled.div`
  margin-left: 26px;
  height: 24px;
  border-left: 2px dotted var(--black-25);
`;
export const NoteHeader = styled.div`
  display: flex;
  padding: 16px;
  > div {
    display: flex;
    flex-direction: column;
  }
  .icon {
    margin-right: 16px;
  }
`;
export const Content = styled.div`
  border-top: 1px solid var(--black-05);
  padding: 16px 16px 0;
`;
export const DateLabel = styled(Label)`
  color: var(--black-50);
  line-height: 16px;
`;
export const ArchivableLabel = styled(Label)<{ $archived: boolean }>`
  text-decoration: ${({ $archived }) => ($archived ? 'line-through' : 'none')};
`;
export const SectionContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
  overflow: hidden;
`;
