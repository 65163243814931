import { useMemo } from 'react';

import { Dimensions, Metrics, Routes, Sources } from 'components/insights/constants';
import MetricTile from 'components/insights/layout/tiles/MetricTile';
import useAnalytics from 'components/insights/query/useAnalytics';
import Histogram from 'components/shared/charts/Histogram';

export default function AverageLengthOfStayTile() {
  const categories = useMemo(
    () => ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80-89', '90+'],
    []
  );

  const queryParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DISCHARGES,
      dimensions: [Dimensions.LENGTH_OF_STAY],
      metrics: [Metrics.ID_COUNT, Metrics.LENGTH_OF_STAY_AVERAGE],
      sortBy: Dimensions.LENGTH_OF_STAY,
      rollups: true,
    }),
    []
  );

  const { customRef, loading, data, rollups } = useAnalytics(queryParams, { onIntersecting: true });

  const lengthOfStayAverageRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.LENGTH_OF_STAY_AVERAGE, 0);
  const lengthOfStays = data.dimensions.currentSeries.flat();
  const lengthOfStayCounts = data.metrics.getCurrentSeries(Metrics.ID_COUNT);

  const histogramData = categories.map((category) =>
    lengthOfStayCounts.reduce((acc: number, row: number, idx: number) => {
      const [min, max = null] = category.split('-').map((value) => parseInt(value, 10));

      const los = Number(lengthOfStays[idx]);
      const include = max ? los >= min && los <= max : los >= min;

      return include ? acc + row : acc;
    }, 0)
  );

  const config: Highcharts.Options = useMemo(
    () => ({
      series: [
        {
          type: 'column',
          data: histogramData,
        },
      ],
      xAxis: {
        categories,
        labels: {
          rotation: 0,
        },
      },
    }),
    [categories, histogramData]
  );

  const value = lengthOfStayAverageRollup != null ? `${lengthOfStayAverageRollup?.toFixed(1)} days` : '';

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsALOS'
      data-cy='insightsALOS'
      label='Average Length of Stay'
      loading={loading}
      hasData={lengthOfStayAverageRollup != null && !!histogramData.length}
      value={value}
      navigateTo={Routes.AVERAGE_LENGTH_OF_STAY}>
      <Histogram
        config={config}
        formatTooltipSupertext={(x) => `${x} Days`}
        formatTooltipSubtext={(y) => `Total Patients: ${y}`}
      />
    </MetricTile>
  );
}
