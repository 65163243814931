import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Dimensions, Metrics } from 'components/insights/constants';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { AnalyticsQueryParams } from 'components/insights/query/useAnalyticsInfiniteQuery';
import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import Card from '../../Card';
import ApiExport from '../ApiExport';
import { CategoryRow } from '../helpers/utils';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import TableContainer from '../TableContainer';

export type OesTableRow = CategoryRow & {
  score: { current: number | null; prior: number | null };
};

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  requestParams: AnalyticsQueryParams;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimension: Dimensions;
};

const OlioEngagementScoreDataTable = ({ loading, requestParams, rollups, selectedDimension, data }: Props) => {
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const categories = useMemo(() => data.dimensions.currentSeries.flat(), [data.dimensions]);

  const overallScore = rollups.overall.metrics.getCurrentSeries(Metrics.OLIO_ENGAGEMENT_SCORE);
  const sumLocationEpisodes = rollups.overall.metrics.getCurrentSeries(Metrics.LOCATION_EPISODE_ID_COUNT);

  const scores = useMemo(() => data.metrics.getCurrentSeries(Metrics.OLIO_ENGAGEMENT_SCORE), [data.metrics]);
  const priorPeriodScores = useMemo(() => data.metrics.getPriorSeries(Metrics.OLIO_ENGAGEMENT_SCORE), [data.metrics]);
  const locationEpisodeCounts = useMemo(
    () => data.metrics.getCurrentSeries(Metrics.LOCATION_EPISODE_ID_COUNT),
    [data.metrics]
  );

  const tableData = useMemo<OesTableRow[]>(
    () =>
      categories.map((categoryName, i) => ({
        categoryName,
        score: {
          current: scores[i],
          prior: priorPeriodScores[i],
        },
        locationEpisodes: locationEpisodeCounts[i] ?? 0,
      })),
    [locationEpisodeCounts, categories, scores, priorPeriodScores]
  );

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case Dimensions.PLAN_TYPE:
        return 'Plan Type';
      case Dimensions.EPISODE_TYPE:
        return 'Episode Type';
      case Dimensions.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columnHelper = createColumnHelper<OesTableRow>();

  const columns = useMemo<ColumnDef<OesTableRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('locationEpisodes', {
        header: 'Patients',
        footer: () => `${sumLocationEpisodes ?? '—'} (Total)`,
      }),
      columnHelper.accessor('score', {
        header: 'Olio Engagement Score (OES)',
        cell: (data) => (
          <>
            <p>{data.getValue().current == null ? <>&mdash;</> : `${data.getValue().current}%`}</p>{' '}
            <MetricDiffBadge
              currentValue={data.getValue().current}
              priorValue={data.getValue().prior}
              comparator={Comparator.GREATER_THAN}
              formatter={(val) => `${val.toFixed()}%`}
            />
          </>
        ),
        footer: () => `${overallScore ?? '—'}%`,
      }),
    ],
    [columnHelper, getTableHeader, sumLocationEpisodes, overallScore]
  );

  const desktopOnlyColumns = ['locationEpisodes'];

  const defaultSort = [
    {
      id: 'score',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>Olio Engagement Score (OES) Overview</Card.Title>
        <ApiExport
          disabled={!tableData.length}
          show={!loading}
          params={requestParams}
          filename='olio-engagement-score-export'
        />
      </TableTitleContainer>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        loading={loading}
      />
    </TableContainer>
  );
};

export default OlioEngagementScoreDataTable;
