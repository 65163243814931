import { useNavigate } from 'react-router-dom';

import { Routes } from 'components/insights/constants';
import ArrowIcon from 'svg/ArrowIcon';

export default function MetricBackArrow() {
  const navigate = useNavigate();

  return <ArrowIcon onClick={() => navigate(Routes.INSIGHTS_BASE)} style={{ cursor: 'pointer' }} />;
}
