import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Dimensions, Metrics } from 'components/insights/constants';
import Card from 'components/insights/layout/Card';
import TableContainer from 'components/insights/layout/details/TableContainer';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { AnalyticsQueryParams } from 'components/insights/query/useAnalyticsInfiniteQuery';
import { formatDuration } from 'components/shared/charts/helpers';
import DataTable from 'components/shared/DataTable';
import { useProfile } from 'context/profile';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import ApiExport from '../ApiExport';
import { CategoryResponseTimeRow } from '../helpers/utils';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimension: Dimensions;
  isPriorityNote: boolean;
  responseTimeLabel: string;
  countLabel: string;
  displayHeaders: { [key: string]: string };
  requestParams: AnalyticsQueryParams;
};

const ResponseTimeDataTable = ({
  countLabel,
  data,
  displayHeaders,
  loading,
  isPriorityNote,
  requestParams,
  responseTimeLabel,
  rollups,
  selectedDimension,
}: Props) => {
  const { profile } = useProfile();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.currentSeries.flat(), [data.dimensions]);

  // Get the metric by index because we dynamically change the metric based on business hours toggle.
  // If the requested metric order changes, this will need to change.
  const responseTimeRollup = rollups.overall.metrics.getCurrentDataPointByIndex(0, 0);
  const escalationAckIdCountRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.ID_COUNT, 0);

  const responseTimes = data.metrics.getCurrentSeriesByIndex(0);
  const priorResponseTimes = data.metrics.getPriorSeriesByIndex(0);
  const escalationAckIdCounts = data.metrics.getCurrentSeries(Metrics.ID_COUNT);

  const tableData = useMemo<CategoryResponseTimeRow[]>(() => {
    return categories.map((categoryName, i) => ({
      categoryName,
      responseTime: {
        current: responseTimes[i],
        prior: priorResponseTimes[i],
      },
      count: escalationAckIdCounts[i],
    }));
  }, [categories, escalationAckIdCounts, priorResponseTimes, responseTimes]);

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case Dimensions.PLAN_TYPE:
        return 'Plan Type';
      case Dimensions.EPISODE_TYPE:
        return 'Episode Type';
      case Dimensions.PROVIDER_CLIENT:
        return isPriorityNote ? `${selectedGroupType?.displayName} Company` : displayHeaders[profile.clientType];
      default:
        return isPriorityNote ? selectedGroupType?.displayName : displayHeaders[profile.clientType];
    }
  }, [displayHeaders, isPriorityNote, profile.clientType, selectedDimension, selectedGroupType?.displayName]);

  const columnHelper = createColumnHelper<CategoryResponseTimeRow>();
  const columns = useMemo<ColumnDef<CategoryResponseTimeRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('responseTime', {
        header: responseTimeLabel,
        cell: (data) => {
          console.log(data.getValue());
          return (
            <>
              <p>
                {data.getValue().current == null ? <>&mdash;</> : `${formatDuration(data.getValue().current, true)}`}
              </p>{' '}
              <MetricDiffBadge
                currentValue={data.getValue().current}
                priorValue={data.getValue().prior}
                comparator={Comparator.LESS_THAN}
                formatter={(val) => `${formatDuration(val, true)}`}
              />
            </>
          );
        },
        footer: () => `${formatDuration(responseTimeRollup, true)} (Avg)`,
      }),
      columnHelper.accessor('count', {
        header: countLabel,
        cell: (data) => data.getValue() ?? <>&mdash;</>,
        footer: () => `${escalationAckIdCountRollup} (Total)`,
      }),
    ],
    [columnHelper, countLabel, escalationAckIdCountRollup, getTableHeader, responseTimeLabel, responseTimeRollup]
  );

  const defaultSort = [
    {
      id: 'count',
      desc: true,
    },
  ];

  const desktopOnlyColumns = ['count'];

  const filePrefix = isPriorityNote ? 'priority-response-time-export' : 'escalation-response-time-export';

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>{responseTimeLabel} Overview</Card.Title>
        <ApiExport disabled={!tableData.length} show={!loading} params={requestParams} filename={filePrefix} />
      </TableTitleContainer>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        loading={loading}
        desktopOnlyColumns={desktopOnlyColumns}
      />
    </TableContainer>
  );
};

export default ResponseTimeDataTable;
