import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Dimensions, Metrics } from 'components/insights/constants';
import Card from 'components/insights/layout/Card';
import { ActivitiesPerPatientPerWeekTableRow } from 'components/insights/layout/details/helpers/activitiesPerPatientPerWeekUtils';
import TableContainer from 'components/insights/layout/details/TableContainer';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { AnalyticsQueryParams } from 'components/insights/query/useAnalyticsInfiniteQuery';
import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import ApiExport from '../ApiExport';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  requestParams: AnalyticsQueryParams;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimension: Dimensions;
};

const ActivitiesPerPatientWeekDataTable = (props: Props) => {
  const { data, loading, requestParams, rollups, selectedDimension } = props;

  const categories = useMemo(() => data.dimensions.currentSeries.flat(), [data.dimensions]);

  const activitiesPerPatientPerWeekRollup = rollups.overall.metrics.getCurrentDataPoint(
    Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK,
    0
  );
  const locationEpisodeIdCountRollup = rollups.overall.metrics.getCurrentDataPoint(
    Metrics.LOCATION_EPISODE_ID_COUNT,
    0
  );
  const ownerActivitiesSumRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.OWNER_ACTIVITIES_SUM, 0);

  const locationEpisodeIdCounts = data.metrics.getCurrentSeries(Metrics.LOCATION_EPISODE_ID_COUNT);
  const ownerActivitiesSums = data.metrics.getCurrentSeries(Metrics.OWNER_ACTIVITIES_SUM);
  const activitiesPerPatientPerWeek = data.metrics.getCurrentSeries(Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK);
  const priorActivitiesPerPatientPerWeek = data.metrics.getPriorSeries(Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK);

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const tableData = useMemo<ActivitiesPerPatientPerWeekTableRow[]>(() => {
    return categories.map((categoryName, i) => ({
      categoryName,
      activitiesPerPatientPerWeek: {
        current: activitiesPerPatientPerWeek[i],
        prior: priorActivitiesPerPatientPerWeek[i],
      },
      totalPatients: locationEpisodeIdCounts[i],
      totalActivities: ownerActivitiesSums[i],
      priorActivitiesPerPatientPerWeek: priorActivitiesPerPatientPerWeek[i],
    }));
  }, [
    activitiesPerPatientPerWeek,
    categories,
    locationEpisodeIdCounts,
    ownerActivitiesSums,
    priorActivitiesPerPatientPerWeek,
  ]);

  const columnHelper = createColumnHelper<ActivitiesPerPatientPerWeekTableRow>();

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case Dimensions.PLAN_TYPE:
        return 'Plan Type';
      case Dimensions.EPISODE_TYPE:
        return 'Episode Type';
      case Dimensions.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columns = useMemo<ColumnDef<ActivitiesPerPatientPerWeekTableRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('activitiesPerPatientPerWeek', {
        header: 'Activities Per Patient Per Week',
        cell: (data) => (
          <>
            <p>{data.getValue().current == null ? <>&mdash;</> : `${data.getValue().current.toFixed(1)}`}</p>{' '}
            <MetricDiffBadge
              currentValue={data.getValue().current}
              priorValue={data.getValue().prior}
              comparator={Comparator.GREATER_THAN}
              formatter={(val) => `${val.toFixed(1)}`}
            />
          </>
        ),
        footer: () => `${activitiesPerPatientPerWeekRollup?.toFixed(1) ?? '—'} (Avg)`,
      }),
      columnHelper.accessor('totalActivities', {
        header: 'Activities',
        cell: (data) => `${data.getValue() ?? '—'}`,
        footer: () => `${ownerActivitiesSumRollup ?? '—'} (Total)`,
      }),
      columnHelper.accessor('totalPatients', {
        header: 'Patients',
        cell: (data) => `${data.getValue() ?? '—'}`,
        footer: () => `${locationEpisodeIdCountRollup ?? '—'} (Total)`,
      }),
    ],
    [
      activitiesPerPatientPerWeekRollup,
      columnHelper,
      getTableHeader,
      ownerActivitiesSumRollup,
      locationEpisodeIdCountRollup,
    ]
  );
  const desktopOnlyColumns = ['totalActivities', 'totalPatients'];

  const defaultSort = [
    {
      id: 'totalPatients',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>Activities Per Patient Per Week Overview</Card.Title>
        <ApiExport
          disabled={!tableData.length}
          show={!loading}
          params={requestParams}
          filename='activities-per-patient-per-week-export'
        />
      </TableTitleContainer>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        loading={loading}
      />
    </TableContainer>
  );
};

export default ActivitiesPerPatientWeekDataTable;
