import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import DischargeForm from 'components/dischargeForm/DischargeForm';
import { DischargeFormMeta, DischargeFormValues } from 'components/dischargeForm/DischargeInformation';
import { buildAnsweredQuestionsFromFormValues } from 'components/dischargeForm/helpers';
import dischargeFormValidation from 'components/dischargeForm/validation';
import useInvalidatedPatient from 'components/patient/useInvalidatedPatientQueries';
import { defaultActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import StandardModal from 'components/shared/StandardModal';
import { Flags } from 'constants/flags';
import { FormProvider } from 'context/form';
import { FormHelpers, FormMeta, FormValues } from 'context/form/types';
import { simpleDashDate } from 'lib/date';
import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';
import LocationEpisodeDischarge from 'models/LocationEpisodeDischarge';
import {
  createLocationEpisodeDischarge,
  showLocationEpisodeDischarge,
  updateLocationEpisodeDischarge,
} from 'services/api/locationEpisodeDischarges';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';
import { useToastActions } from 'stores/toastStore';

export type DischargeModalType = {
  setShow: (b: boolean) => void;
  patientName: string;
  episode: Episode;
  locationEpisode: LocationEpisode;
  invalidateData?: () => void;
};

export default function DischargeModal(props: DischargeModalType) {
  const { setShow, patientName, episode, invalidateData } = props;

  const invalidatePatientQueries = useInvalidatedPatient();
  const { addToast } = useToastActions();

  const [locationEpisode, setLocationEpisode] = useState<LocationEpisode>(props.locationEpisode);

  const isDischargeEdit = locationEpisode.id === episode.latestLocationEpisodeDischarge?.locationEpisodeId;

  const locationEpisodeDischargeQueryParams = {
    id: episode.latestLocationEpisodeDischarge?.id ?? '',
    locationEpisodeId: locationEpisode.id,
    include:
      'group_type,group,location_episode.owner.client.enabled_flags,location_episode.question_templates,discharge_reason.discharged_to_group_type,activity',
  };

  const { data: locationEpisodeDischarge } = useQuery({
    queryKey: locationEpisodeQueryKeys.show(locationEpisodeDischargeQueryParams),
    queryFn: () => showLocationEpisodeDischarge(locationEpisodeDischargeQueryParams),
    enabled: isDischargeEdit,
  });

  useEffect(() => {
    if (isDischargeEdit && locationEpisodeDischarge?.locationEpisode) {
      setLocationEpisode(locationEpisodeDischarge.locationEpisode);
    }
  }, [isDischargeEdit, locationEpisode, locationEpisodeDischarge]);

  const { mutate } = useMutation({
    mutationFn: isDischargeEdit ? updateLocationEpisodeDischarge : createLocationEpisodeDischarge,
  });

  const cancel = () => {
    setShow(false);
  };

  const handleSubmit = async (
    values: FormValues<DischargeFormValues>,
    meta: FormMeta<DischargeFormMeta>,
    helpers: FormHelpers<DischargeFormValues, DischargeFormMeta>
  ) => {
    helpers.setMeta('isSubmitting', true);

    const dischargeValues = LocationEpisodeDischarge.fromFormValues(values).serialize();

    mutate(
      {
        ...dischargeValues,
        locationEpisodeId: locationEpisode.id,
        dischargeQuestions: buildAnsweredQuestionsFromFormValues(
          locationEpisode.dischargeTemplate?.questions || [],
          values.questions || {}
        ),
        enteredAt: simpleDashDate(values.actualDischargeDate ?? new Date()) as string,
        note: values.note
          ? {
              ...values.note,
              attachments: values.note.attachments?.map((upload) => upload.serialize()),
            }
          : undefined,
      },
      {
        onSuccess: () => {
          invalidatePatientQueries({
            episodes: true,
            activities: true,
          });
          invalidateData?.();
          cancel();
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again' });
        },
        onSettled: () => {
          helpers.setMeta('isSubmitting', false);
        },
      }
    );
  };

  const initialValues = {
    ...(isDischargeEdit && locationEpisodeDischarge?.dischargeFormValues()),
    note: defaultActivityInputValues,
  };

  return (
    <StandardModal title={isDischargeEdit ? 'Edit Discharge' : 'Discharge Patient'} onCancel={cancel}>
      <FormProvider<DischargeFormValues, DischargeFormMeta>
        resetOnInitialValuesChange
        initialValues={initialValues}
        onSubmit={handleSubmit}
        yupSchema={dischargeFormValidation(locationEpisode.currentRehabState?.enteredAt)}>
        <DischargeForm
          locationEpisode={locationEpisode}
          patientName={patientName}
          onCancel={cancel}
          dischargeQuestionsFlagEnabled={locationEpisode.owner.client.hasFlag(Flags.DischargeQuestions)}
          showActualDischargeDate
        />
      </FormProvider>
    </StandardModal>
  );
}
