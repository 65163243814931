import { useMemo, useState } from 'react';

import { Dimensions, Metrics, SortOrder, Sources } from 'components/insights/constants';
import { useInsightsContext } from 'components/insights/InsightsContainer';
import MetricBackArrow from 'components/insights/layout/details/MetricBackArrow';
import MetricDetailControlBar from 'components/insights/layout/details/MetricDetailControlBar';
import Separator from 'components/insights/layout/Separator';
import useAnalytics from 'components/insights/query/useAnalytics';

import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';

import ActivitiesPerPatientWeekChart from './ActivitiesPerPatientWeekChart';
import ActivitiesPerPatientWeekDataTable from './ActivitiesPerPatientWeekDataTable';

const ActivitiesPerPatientWeekDetail = () => {
  const { profile } = useInsightsContext();

  const [selectedDimension, setSelectedDimension] = useState<Dimensions>(
    profile.isAcute ? Dimensions.PROVIDER_CLIENT : Dimensions.GROUP_NAME
  );

  const requestParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DAYS,
      dimensions: [selectedDimension],
      metrics: [
        Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK,
        Metrics.LOCATION_EPISODE_ID_COUNT,
        Metrics.OWNER_ACTIVITIES_SUM,
      ],
      sortBy: `${Metrics.LOCATION_EPISODE_ID_COUNT} ${SortOrder.DESC}`,
      rollups: true,
    }),
    [selectedDimension]
  );

  const analyticsResponse = useAnalytics(requestParams);

  return (
    <>
      <MetricDetailControlBar>
        <MetricBackArrow />
        <Separator height='20px' />
        <ComparePriorPeriodToggle />
      </MetricDetailControlBar>
      <ActivitiesPerPatientWeekChart
        {...analyticsResponse}
        onSelectedDimensionChange={setSelectedDimension}
        selectedDimension={selectedDimension}
      />
      <ActivitiesPerPatientWeekDataTable
        {...analyticsResponse}
        requestParams={requestParams}
        selectedDimension={selectedDimension}
      />
    </>
  );
};

export default ActivitiesPerPatientWeekDetail;
