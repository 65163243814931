import AnalyticsDimensionCollection from './AnalyticsDimensionCollection';
import AnalyticsMetricCollection from './AnalyticsMetricCollection';
import AnalyticsResponse, { ResponseRow } from './AnalyticsResponse';

class AnalyticsResponseRollupGrouping {
  analyticsResponse: AnalyticsResponse;
  current: ResponseRow[];
  prior: ResponseRow[];
  dimensions: AnalyticsDimensionCollection;
  metrics: AnalyticsMetricCollection;

  constructor(analyticsResponse: AnalyticsResponse, currentRows: ResponseRow[], priorRows: ResponseRow[]) {
    this.analyticsResponse = analyticsResponse;
    this.current = currentRows;
    this.prior = priorRows;
    this.dimensions = new AnalyticsDimensionCollection(currentRows, priorRows);
    this.metrics = new AnalyticsMetricCollection(analyticsResponse, this.dimensions, currentRows, priorRows);
  }
}

export default class AnalyticsResponseRollupCollection {
  analyticsResponse: AnalyticsResponse;
  groupings: AnalyticsResponseRollupGrouping[];

  constructor(analyticsResponse: AnalyticsResponse) {
    this.analyticsResponse = analyticsResponse;
    this.groupings = this.current.map((row, idx) => {
      return new AnalyticsResponseRollupGrouping(
        analyticsResponse,
        row,
        this.prior[idx] || new Array<Array<ResponseRow>>()
      );
    });
  }

  get responseRollups() {
    return this.analyticsResponse.responseData
      .filter((row) => row.grouping && row.grouping !== 0)
      .sort((a, b) => ((a?.grouping ?? 0) > (b?.grouping ?? 0) ? -1 : 1))
      .map((row) => new ResponseRow(row)) as ResponseRow[];
  }

  get current() {
    const currentRollups = this.responseRollups.filter((row) => row.datesIndex === 0);

    const groupedRollups = this.groupByGrouping(currentRollups);

    return (
      Object.values(groupedRollups)
        // Reverse because when they are grouped via groupByGrouping,
        // they are sorted in the hash ascending order by "grouping" value.
        // Reverse the sort so the overall rollup is always first.
        .reverse()
        .map((group) => group) as ResponseRow[][]
    );
  }

  get prior() {
    const priorRollups = this.responseRollups.filter((row) => row.datesIndex === 1);

    const groupedRollups = this.groupByGrouping(priorRollups);

    return (
      Object.values(groupedRollups)
        // Reverse because when they are grouped via groupByGrouping,
        // they are sorted in the hash ascending order by "grouping" value.
        // Reverse the sort so the overall rollup is always first.
        .reverse()
        .map((group) => group) as ResponseRow[][]
    );
  }

  get overall() {
    return this.groupings[0] || new AnalyticsResponseRollupGrouping(this.analyticsResponse, [], []);
  }

  private groupByGrouping(rows: ResponseRow[]) {
    return rows.reduce((acc, row) => {
      const key = row.grouping ?? 'null';
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(row);
      return acc;
    }, {});
  }
}
