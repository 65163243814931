import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import LocationEpisodeDischarge, { LocationEpisodeDischargeOptions } from 'models/LocationEpisodeDischarge';
import { Note } from 'models/Notes';
import { BaseQueryParams } from 'services/api/types';
import http from 'services/http';

export const locationEpisodeDischargeQueryKeys = {
  base: ['location_episode_discharges'],
  show: (params: Record<string, any> = {}) => ['location_episode_discharges', 'show', params],
};

type ShowParams = {
  id: string;
  locationEpisodeId: string;
} & BaseQueryParams;
export async function showLocationEpisodeDischarge({ id, locationEpisodeId, ...params }: ShowParams) {
  return http
    .get<LocationEpisodeDischargeOptions>(`location_episodes/${locationEpisodeId}/location_episode_discharges/${id}`, {
      params,
    })
    .then((res) => new LocationEpisodeDischarge(res.data));
}

export type CreateParams = {
  locationEpisodeId: string;
  dischargeReasonId: string;
  dischargedGroupId?: string | null;
  dischargedGroupTypeId?: string | null;
  dischargedLocationOther: string | null;
  againstMedicalAdvice: boolean;
  dischargeQuestions: AnswerType[];
  enteredAt: string;
  note?: Note;
};

export async function createLocationEpisodeDischarge({ locationEpisodeId, ...params }: CreateParams) {
  return http
    .post<LocationEpisodeDischargeOptions>(`location_episodes/${locationEpisodeId}/location_episode_discharges`, params)
    .then((res) => new LocationEpisodeDischarge(res.data));
}

export type UpdateParams = CreateParams & {
  id: string;
};

export async function updateLocationEpisodeDischarge({ id, locationEpisodeId, ...params }: UpdateParams) {
  return http
    .put<LocationEpisodeDischargeOptions>(
      `location_episodes/${locationEpisodeId}/location_episode_discharges/${id}`,
      params
    )
    .then((res) => new LocationEpisodeDischarge(res.data));
}
