import { DischargeFormValues } from 'components/dischargeForm/DischargeInformation';
import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import { FormValues } from 'context/form/types';
import Activity, { ActivityOptions } from 'models/Activity';
import DischargeReason, { DischargeReasonOptions } from 'models/DischargeReason';
import Group, { GroupOptions } from 'models/Group';
import GroupType, { GroupTypeOptions } from 'models/GroupType';
import LocationEpisode, { LocationEpisodeOptions } from 'models/LocationEpisode';

export interface LocationEpisodeDischargeOptions {
  id: string;
  locationEpisodeId: string;
  dischargeReasonId: string;
  groupId: string | null;
  groupTypeId: string | null;
  locationOther: string | null;
  activityId: string | null;
  againstMedicalAdvice: boolean;
  createdAt: string | null;
  updatedAt: string | null;
  locationEpisode?: Partial<LocationEpisodeOptions> | null;
  groupType?: Partial<GroupTypeOptions> | null;
  group?: Partial<GroupOptions> | null;
  dischargeReason?: Partial<DischargeReasonOptions> | null;
  activity?: Partial<ActivityOptions> | null;
}

function getDefaults(): LocationEpisodeDischargeOptions {
  return {
    id: '',
    locationEpisodeId: '',
    dischargeReasonId: '',
    groupId: null,
    groupTypeId: null,
    locationOther: null,
    activityId: null,
    againstMedicalAdvice: false,
    createdAt: null,
    updatedAt: null,
    locationEpisode: null,
    groupType: null,
    group: null,
    dischargeReason: null,
    activity: null,
  };
}

export default class LocationEpisodeDischarge {
  id: string;
  locationEpisodeId: string;
  dischargeReasonId: string;
  groupId: string | null;
  groupTypeId: string | null;
  locationOther: string | null;
  activityId: string | null;
  againstMedicalAdvice: boolean;
  createdAt: string | null;
  updatedAt: string | null;
  locationEpisode: LocationEpisode | null;
  groupType: GroupType | null;
  group: Group | null;
  dischargeReason: DischargeReason | null;
  activity: Activity | null;

  constructor(options: Partial<LocationEpisodeDischargeOptions> = {}) {
    const opts = { ...getDefaults(), ...options };
    this.id = opts.id;
    this.locationEpisodeId = opts.locationEpisodeId;
    this.dischargeReasonId = opts.dischargeReasonId;
    this.groupId = opts.groupId;
    this.groupTypeId = opts.groupTypeId;
    this.locationOther = opts.locationOther;
    this.activityId = opts.activityId;
    this.againstMedicalAdvice = opts.againstMedicalAdvice;
    this.createdAt = opts.createdAt;
    this.updatedAt = opts.updatedAt;
    this.locationEpisode = opts.locationEpisode ? new LocationEpisode(opts.locationEpisode) : null;
    this.groupType = opts.groupType ? new GroupType(opts.groupType) : null;
    this.group = opts.group ? new Group(opts.group) : null;
    this.dischargeReason = opts.dischargeReason ? new DischargeReason(opts.dischargeReason) : null;
    this.activity = opts.activity ? new Activity(opts.activity) : null;
  }

  static fromFormValues(values: Partial<FormValues<DischargeFormValues>>) {
    return new LocationEpisodeDischarge({
      id: values.id ?? '',
      dischargeReasonId: values.dischargeReason?.id ?? '',
      groupId: values.dischargedGroup?.id ?? undefined,
      groupTypeId: values.dischargeReason?.dischargedToGroupTypeId ?? undefined,
      locationOther: values.dischargedLocationOther || null,
      againstMedicalAdvice: values.againstMedicalAdvice || false,
    });
  }

  dischargeFormValues(): Partial<FormValues<DischargeFormValues>> {
    const formQuestions = (questions: AnswerType[]) =>
      Object.assign({}, ...questions.map((q) => ({ [q.id]: q.answer.text })));

    return {
      id: this.id,
      actualDischargeDate: new Date(this.locationEpisode?.currentRehabState.enteredAt ?? ''),
      againstMedicalAdvice: this.againstMedicalAdvice,
      dischargeReason: this.dischargeReason,
      dischargedGroup: this.locationOther ? new Group({ name: 'Other' }) : this.group,
      dischargedLocationOther: this.locationOther || '',
      questions: this.activity ? formQuestions(this.activity.data.questions) : null,
    };
  }

  serialize() {
    return {
      id: this.id,
      dischargeReasonId: this.dischargeReasonId,
      dischargedGroupId: this.groupId,
      dischargedGroupTypeId: this.groupTypeId,
      dischargedLocationOther: this.locationOther,
      againstMedicalAdvice: this.againstMedicalAdvice,
    };
  }
}
