import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { activityQueryKeys } from 'services/api/activity';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { episodeQueryKeys } from 'services/api/episode';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';

type InvalidateOptions = {
  episode?: boolean | Record<string, any>;
  episodes?: boolean;
  locationEpisode?: boolean | Record<string, any>;
  activities?: boolean;
  attachmentsCount?: boolean | Record<string, any>;
};

const getParams = (option: boolean | Record<string, any>) => (typeof option === 'object' ? { ...option } : {});

export default function useInvalidatedPatientQueries() {
  const queryClient = useQueryClient();

  return useCallback(
    ({
      episode = false,
      episodes = false,
      locationEpisode = false,
      activities = false,
      attachmentsCount = false,
    }: InvalidateOptions = {}) => {
      if (episode) queryClient.invalidateQueries({ queryKey: episodeQueryKeys.show(getParams(episode)) });
      if (episodes) queryClient.invalidateQueries({ queryKey: episodeQueryKeys.base });
      if (locationEpisode)
        queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show(getParams(locationEpisode)) });
      if (activities) queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
      if (attachmentsCount)
        queryClient.invalidateQueries({ queryKey: attachmentsQueryKeys.count(getParams(attachmentsCount)) });
    },
    [queryClient]
  );
}
