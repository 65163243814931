import Highcharts, { SeriesOptionsType, TooltipFormatterContextObject } from 'highcharts';

import { Metrics } from 'components/insights/constants';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import colors from 'styles/theme/colors';

export type ActivitiesPerPatientPerWeekTableRow = {
  categoryName: string;
  activitiesPerPatientPerWeek: { current: number | null; prior: number | null };
  totalActivities: number | null;
  totalPatients: number | null;
};

// Calculates the scrollable width of the chart based on the number of data points
const calculateWidth = (pointWidth: number, dataLength: number) => {
  return (pointWidth + pointWidth * 1.5) * dataLength;
};

export const generateExtendedChartConfig = ({
  categories,
  data,
  rollups,
}: {
  categories: string[];
  data: AnalyticsResponseData;
  rollups: AnalyticsResponseRollupCollection;
}): Highcharts.Options => {
  const activitiesPerPatientPerWeekRollup = rollups.overall.metrics.getCurrentDataPoint(
    Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK,
    0
  );

  const locationEpisodeIdCounts = data.metrics.getCurrentSeries(Metrics.LOCATION_EPISODE_ID_COUNT);
  const activitiesPerPatientPerWeek = data.metrics.getCurrentSeries(Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK);
  const priorActivitiesPerPatientPerWeek = data.metrics.getPriorSeries(Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK);

  return {
    chart: {
      scrollablePlotArea: {
        minWidth: calculateWidth(40, activitiesPerPatientPerWeek?.length),
        scrollPositionX: 0,
      },
    },
    legend: {
      enabled: true,
      padding: 0,
      itemMarginBottom: 10,
      itemMarginTop: 16,
      align: 'center',
      symbolRadius: 2,
      x: 0,
      verticalAlign: 'bottom',
      y: 0,
      itemStyle: {
        fontSize: '12px',
      },
    },
    xAxis: {
      categories: categories,
      lineWidth: 1,
      lineColor: colors.black25,
      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          // This must remain one line.  This "html" string is interpreted by Highcharts and is converted to SVG.
          // If this string is broken into multiple lines, highcharts will sometimes incorrectly render an extra line break in the label.
          return `<span style="color:#0f1226;font-size:10px;">${this.value}</span><br/><span style="color:rgba(15, 18, 38, .5);font-size:8px;">Patients: ${locationEpisodeIdCounts[this.pos]}</span>`;
        },
      },
    },
    scrollbar: {
      enabled: true,
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineColor: colors.black10,
      plotLines: [
        {
          color: '#F06532',
          width: 2,
          value: activitiesPerPatientPerWeekRollup ?? 0,
          dashStyle: 'ShortDash',
        },
      ],
    },
    plotOptions: {
      column: {
        maxPointWidth: 40,
        events: { legendItemClick: () => false, mouseOver: () => false },
        dataLabels: {
          enabled: true,
          formatter: function (this: TooltipFormatterContextObject): string {
            return `<span style="font-size:10px;font-weight:normal;">${this.point.label}</span>`;
          },
        },
      },
      line: {
        enableMouseTracking: false,
        events: { legendItemClick: () => false, mouseOver: () => false },
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        type: 'column',
        data: activitiesPerPatientPerWeek?.map((val) => ({ y: val ?? 0, label: val == null ? 'N/A' : val.toFixed(1) })),
        name: 'Activity Per Patient Per Week',
        states: {
          inactive: {
            opacity: 0.05,
          },
        },
      },
      ...(priorActivitiesPerPatientPerWeek?.length > 0
        ? [
            {
              type: 'column',
              data: priorActivitiesPerPatientPerWeek?.map((val) => ({
                y: val ?? 0,
                label: val == null ? 'N/A' : val.toFixed(1),
              })),
              name: 'Prior Activity',
              color: '#C1CBFA',
            } as SeriesOptionsType,
          ]
        : []),
      {
        color: '#F06532',
        name: 'Average',
        type: 'line',
        dashStyle: 'ShortDash',
        data: [activitiesPerPatientPerWeekRollup],
      },
    ],
  };
};
