import AnalyticsDimensionCollection from './AnalyticsDimensionCollection';
import AnalyticsMetricCollection from './AnalyticsMetricCollection';
import AnalyticsResponse, { ResponseRow } from './AnalyticsResponse';

export default class AnalyticsResponseData {
  analyticsResponse: AnalyticsResponse;
  dimensions: AnalyticsDimensionCollection;
  metrics: AnalyticsMetricCollection;

  constructor(analyticsResponse: AnalyticsResponse) {
    this.analyticsResponse = analyticsResponse;
    this.dimensions = new AnalyticsDimensionCollection(this.current, this.prior);
    this.metrics = new AnalyticsMetricCollection(analyticsResponse, this.dimensions, this.current, this.prior);
  }

  private get responseData() {
    return this.analyticsResponse.responseData
      .filter((row) => !row.grouping || row.grouping === 0)
      .sort((a, b) => ((a?.grouping ?? 0) > (b?.grouping ?? 0) ? -1 : 1))
      .map((row) => new ResponseRow(row)) as ResponseRow[];
  }

  get current() {
    return this.responseData.filter((row) => row.datesIndex === 0);
  }

  get prior() {
    return this.responseData.filter((row) => row.datesIndex === 1);
  }
}
