import { useMemo } from 'react';

import { Dimensions, Metrics } from 'components/insights/constants';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import Histogram from 'components/shared/charts/Histogram';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import ChartContainer from '../ChartContainer';
import { generateExtendedChartConfig } from '../helpers/oesUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDetailHeader from '../MetricDetailHeader';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

type OlioEngagementScoreChartProps = {
  data: AnalyticsResponseData;
  loading: boolean;
  onSelectedDimensionChange: (selectedDimension: Dimensions) => void;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimension: Dimensions;
};

const OlioEngagementScoreChart = (props: OlioEngagementScoreChartProps) => {
  const { data, loading, onSelectedDimensionChange, rollups, selectedDimension } = props;
  const isMobile = useIsMobile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.series.flat(), [data?.dimensions]);
  const oesRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.OLIO_ENGAGEMENT_SCORE, 0);
  const priorOesRollup = rollups.overall.metrics.getPriorDataPoint(Metrics.OLIO_ENGAGEMENT_SCORE, 0);

  const chartConfig = useMemo(
    () =>
      generateExtendedChartConfig({
        categories,
        series: data.metrics.getSeries(Metrics.OLIO_ENGAGEMENT_SCORE),
      }),
    [categories, data]
  );

  return (
    <MetricDetailContainer
      loading={loading}
      hasData={!!categories.length}
      header={
        <>
          <MetricDetailHeader
            label='Olio Engagement Score (OES)'
            hasData={!!categories.length}
            loading={loading}
            value={
              <>
                {oesRollup == null ? '—' : `${oesRollup}%`}
                <MetricDiffBadge
                  currentValue={oesRollup}
                  priorValue={priorOesRollup}
                  comparator={Comparator.GREATER_THAN}
                  formatter={(val) => `${val.toFixed()}%`}
                />
              </>
            }
            tooltipText='Measurement of engagement activity in Olio'
          />
          {!isMobile && (
            <ViewBySelect
              selectedDimension={selectedDimension}
              groupType={selectedGroupType}
              onChange={(selectedOption) => {
                onSelectedDimensionChange(selectedOption.value);
              }}></ViewBySelect>
          )}
        </>
      }>
      <ChartContainer>
        <Histogram config={chartConfig} />
      </ChartContainer>
    </MetricDetailContainer>
  );
};

export default OlioEngagementScoreChart;
