export function capitalize(str: string) {
  return str.replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase());
}

export function humanize(str: string) {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function snakeCase(str: string) {
  return (
    str &&
    (str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g) || [])
      ?.map((x: string) => x.toLowerCase())
      ?.join('_')
  );
}

export function isDeeplyEqual(a: any, b: any) {
  if (a === b) return true;

  if (typeof a !== 'object' || typeof b !== 'object') return false;

  if (Object.keys(a).length !== Object.keys(b).length) return false;

  for (const key in a) {
    if (!isDeeplyEqual(a[key], b[key])) return false;
  }

  return true;
}
