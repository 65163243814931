import Highcharts, { SeriesOptionsType, TooltipFormatterContextObject } from 'highcharts';

// https://www.highcharts.com/forum/viewtopic.php?t=48229 for reference
Highcharts.seriesType('column2', 'column', {});

import { Metrics } from 'components/insights/constants';
import AnalyticsMetricCollection from 'components/insights/query/AnalyticsMetricCollection';
import colors from 'styles/theme/colors';

// Calculates the scrollable width of the chart based on the number of data points
const calculateWidth = (pointWidth: number, dataLength: number) => {
  return (pointWidth + pointWidth * 1.5) * dataLength;
};

const getDischargesData = (dischargeCounts: (number | null)[], readmissionRates: (number | null)[]) => {
  return dischargeCounts.map((value, i) => {
    return {
      y: value ?? 0, // Converts the null value to 0 so it gets plotted
      label: value == null ? 'N/A' : `${readmissionRates[i]?.toFixed(1)}%`,
      dataLabels: {
        enabled: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          return `<span style="font-size:10px;font-weight:normal;">${this.point.label}</span>`;
        },
      },
    };
  });
};

export const generateExtendedChartConfig = ({
  categories,
  series,
}: {
  categories: string[];
  series: AnalyticsMetricCollection;
}): Highcharts.Options => {
  const dischargeCounts = series.getCurrentSeries(Metrics.ID_COUNT);
  const priorDischargeCounts = series.getPriorSeries(Metrics.ID_COUNT);
  const readmissionsSum = series.getCurrentSeries(Metrics.READMISSIONS_SUM);
  const priorReadmissionsSum = series.getPriorSeries(Metrics.READMISSIONS_SUM);
  const readmissionRates = series.getCurrentSeries(Metrics.READMISSION_RATE);
  const priorReadmissionRates = series.getPriorSeries(Metrics.READMISSION_RATE);

  return {
    chart: {
      scrollablePlotArea: {
        minWidth: calculateWidth(40, categories.length),
        scrollPositionX: 0,
      },
    },
    legend: {
      enabled: true,
      padding: 0,
      itemMarginBottom: 10,
      itemMarginTop: 16,
      align: 'center',
      symbolRadius: 2,
      x: 0,
      verticalAlign: 'bottom',
      y: 0,
      itemStyle: {
        fontSize: '12px',
      },
    },
    xAxis: [
      {
        categories: categories,
        lineWidth: 1,
        lineColor: colors.black25,
        labels: {
          style: {
            fontSize: '10px',
            color: colors.black,
          },
        },
      },
      {
        categories: categories,
        lineWidth: 1,
        visible: false,
      },
    ],
    scrollbar: {
      enabled: true,
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineColor: colors.black10,
    },
    plotOptions: {
      column: {
        maxPointWidth: 40,
        events: { legendItemClick: () => false, mouseOver: () => false },
      },
      // @ts-expect-error column2 does not exist on the plotOptions type, but is custom to this chart.  See above link for example by highcharts dev
      column2: {
        maxPointWidth: 40,
        events: { legendItemClick: () => false, mouseOver: () => false },
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        name: 'Discharges',
        color: colors.black15,
        type: 'column',
        yAxis: 0,
        data: getDischargesData(dischargeCounts, readmissionRates),
      },
      {
        name: 'Readmissions',
        color: '#DC2853',
        type: 'column2' as 'column',
        yAxis: 0,
        data: readmissionsSum,
      },

      ...(priorReadmissionsSum.length > 0
        ? [
            {
              type: 'column',
              data: getDischargesData(priorDischargeCounts, priorReadmissionRates),
              showInLegend: false,
              color: colors.black15,
            } as SeriesOptionsType,
            {
              type: 'column2' as 'column',
              data: priorReadmissionsSum,
              name: 'Prior Readmissions',
              color: '#E8ADBE',
            } as SeriesOptionsType,
          ]
        : []),
    ],
  };
};
