import { useMemo } from 'react';
import styled from 'styled-components';

import { Dimensions, Metrics, Routes, Sources } from 'components/insights/constants';
import useAnalytics from 'components/insights/query/useAnalytics';
import AreaChart from 'components/shared/charts/AreaChart';
import { formatDateLabel, formatDuration, getFormattedDatesInRange } from 'components/shared/charts/helpers';
import { useInsightsStore } from 'stores/insightsStore';
import colors from 'styles/theme/colors';
import EscalationCountIcon from 'svg/EscalationCountIcon';

import MetricTile from './MetricTile';

export default function EscalationResponseTimeTile() {
  const filters = useInsightsStore((state) => state.filters);

  const queryParams = useMemo(
    () => ({
      source: Sources.ESCALATION_ACKS,
      dimensions: [Dimensions.DATE],
      metrics: [Metrics.BUSINESS_RESPONSE_TIME_AVERAGE, Metrics.ID_COUNT],
      rollups: true,
      escalationType: 'escalation',
    }),
    []
  );

  const { customRef, loading, data, rollups } = useAnalytics(queryParams, { onIntersecting: true });

  const chartData = getFormattedDatesInRange(filters.startDate as Date, filters.endDate as Date).reduce(
    (acc, formattedDate) => {
      acc[formattedDate] = { responseTime: 0, escalationsForDate: 0 };
      return acc;
    },
    {}
  );

  const businessResponseTimeAverageRollup = rollups.overall.metrics.getCurrentDataPoint(
    Metrics.BUSINESS_RESPONSE_TIME_AVERAGE,
    0
  );
  const escalationsCountRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.ID_COUNT, 0);
  const businessResponseTimeAverages = data.metrics.getCurrentSeries(Metrics.BUSINESS_RESPONSE_TIME_AVERAGE);
  const escalationCounts = data.metrics.getCurrentSeries(Metrics.ID_COUNT);

  data.dimensions.currentSeries.flat().forEach((row: string, idx: number) => {
    chartData[row] = { responseTime: businessResponseTimeAverages[idx], escalationsForDate: escalationCounts[idx] };
  });

  return (
    <MetricTile
      ref={customRef}
      label={'Escalation Response Time'}
      showValue={true}
      loading={loading}
      hasData={
        businessResponseTimeAverageRollup != null &&
        Object.values(chartData || {})
          .map((data: any) => data.responseTime)
          .some((x) => x > 0)
      }
      value={`${formatDuration(businessResponseTimeAverageRollup, true)}`}
      navigateTo={Routes.ESCALATIONS_RESPONSE}>
      <AreaChart
        formatTooltipSupertext={(x) => `${formatDateLabel(x as string)}`}
        formatTooltipSubtext={(y, key) =>
          `<div style="padding-top: 4px;">
            <span style="font-size: 12px;">Average Response Time: ${y ? formatDuration(y, true) : 'N/A'}</span></div>
            <div>
            <span style="font-size: 12px;">Escalations: ${chartData[key].escalationsForDate}</span></div>`
        }
        categories={Object.keys(chartData || {})}
        values={Object.values(chartData || {}).map((data: any) => data.responseTime)}
        config={{
          yAxis: {
            tickAmount: 3,
            labels: {
              formatter: function () {
                return formatDuration(this.value);
              },
            },
          },
          xAxis: {
            labels: {
              formatter: function () {
                return formatDateLabel(this.value as string);
              },
            },
          },
        }}
      />
      <EscalationsCountContainer>
        <Left>
          <EscalationCountIcon />
          <p>Escalations</p>
        </Left>
        <div>
          <p>{escalationsCountRollup}</p>
        </div>
      </EscalationsCountContainer>
    </MetricTile>
  );
}

const EscalationsCountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 8px;
  border: 1px solid ${colors.black10};
  border-radius: 4px;
  padding: 12px 16px;

  div {
    display: flex;

    p {
      font-size: 10px;
      font-weight: bold;
    }
  }
`;

const Left = styled.div`
  p {
    margin-left: 8px;
  }
`;
