import { useMemo, useState } from 'react';

import { Dimensions, Metrics, SortOrder, Sources } from 'components/insights/constants';
import { useInsightsContext } from 'components/insights/InsightsContainer';
import MetricBackArrow from 'components/insights/layout/details/MetricBackArrow';
import MetricDetailControlBar from 'components/insights/layout/details/MetricDetailControlBar';
import Separator from 'components/insights/layout/Separator';
import useAnalytics from 'components/insights/query/useAnalytics';

import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';

import AverageLengthOfStayChart from './AverageLengthOfStayChart';
import AverageLengthOfStayDataTable from './AverageLengthOfStayDataTable';

const AverageLengthOfStayDetail = () => {
  const { profile } = useInsightsContext();

  const [selectedDimension, setSelectedDimension] = useState<Dimensions>(
    profile.isAcute ? Dimensions.PROVIDER_CLIENT : Dimensions.GROUP_NAME
  );

  const requestParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DISCHARGES,
      dimensions: [selectedDimension],
      metrics: [Metrics.LENGTH_OF_STAY_AVERAGE, Metrics.ID_COUNT],
      sortBy: `${Metrics.ID_COUNT} ${SortOrder.DESC}, ${Dimensions.GROUP_NAME} ${SortOrder.DESC}`,
      rollups: true,
    }),
    [selectedDimension]
  );

  const analyticsResponse = useAnalytics(requestParams);

  return (
    <>
      <MetricDetailControlBar>
        <MetricBackArrow />
        <Separator height='20px' />
        <ComparePriorPeriodToggle />
      </MetricDetailControlBar>
      <AverageLengthOfStayChart
        {...analyticsResponse}
        onSelectedDimensionChange={setSelectedDimension}
        selectedDimension={selectedDimension}
      />
      <AverageLengthOfStayDataTable
        {...analyticsResponse}
        requestParams={requestParams}
        selectedDimension={selectedDimension}
      />
    </>
  );
};

export default AverageLengthOfStayDetail;
