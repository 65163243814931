import { InfiniteData } from '@tanstack/react-query';

import { snakeCase } from 'lib/util';
import { Analytics } from 'services/api/insights/analytics';

import AnalyticsResponseData from './AnalyticsResponseData';
import AnalyticsRollupCollection from './AnalyticsResponseRollupCollection';

export class ResponseRow {
  dimensionValues: Array<{ value: string | number | null }>;
  metricValues: Array<{ value: string | number | null }>;
  grouping?: number;
  datesIndex: number;

  constructor(options: Omit<ResponseRow, 'formattedDimensionValues'>) {
    this.dimensionValues = options.dimensionValues;
    this.metricValues = options.metricValues;
    this.grouping = options.grouping;
    this.datesIndex = options.datesIndex;
  }

  get formattedDimensionValues() {
    return this.dimensionValues.map(({ value }) => value?.toString() ?? 'None');
  }
}

export class ResponsePage {
  data: Array<ResponseRow>;
  dimensionHeaders: { name: string }[];
  metricHeaders: { name: string }[];

  constructor(data: any, dimensionHeaders: any, metricHeaders: any) {
    this.data = data;
    this.dimensionHeaders = dimensionHeaders;
    this.metricHeaders = metricHeaders;
  }
}

export default class AnalyticsResponse {
  response: InfiniteData<Analytics, Analytics> | undefined;
  comparePriorPeriod: boolean;
  data: AnalyticsResponseData;
  rollups: AnalyticsRollupCollection;

  constructor(response: InfiniteData<Analytics, Analytics> | undefined, comparePriorPeriod: boolean) {
    this.response = response;
    this.comparePriorPeriod = comparePriorPeriod;
    this.data = new AnalyticsResponseData(this);
    this.rollups = new AnalyticsRollupCollection(this);
  }

  get dimensionHeaders() {
    return this.response?.pages[0]?.dimensionHeaders || new Array<{ name: string }>();
  }

  get dimensionHeaderNames() {
    return this.dimensionHeaders.map(({ name }) => name);
  }

  get metricHeaders() {
    return this.response?.pages[0]?.metricHeaders || new Array<{ name: string }>();
  }

  get responseData() {
    return this.response?.pages.flatMap((page) => page?.data || new Array<ResponseRow>()) || new Array<ResponseRow>();
  }

  findDimensionIndex(dimensionName: string) {
    return this.dimensionHeaders.findIndex((header) => header.name === snakeCase(dimensionName));
  }

  findMetricIndex(metricName: string) {
    return this.metricHeaders.findIndex((header) => header.name === snakeCase(metricName));
  }

  parseMetricValues = ({ metricValues }: ResponseRow) => metricValues.map(({ value }) => Number(value ?? -1));

  metricValueGatherer(dimensionSeries: string[][], currentRows: ResponseRow[], priorRows: ResponseRow[]) {
    const result = new Array<Array<Array<number | null>>>();
    dimensionSeries.forEach((dimensionValues) => {
      const stringifiedDimensionValues = dimensionValues.toString();
      const matchingCurrentRow = currentRows?.find((row) => {
        return row.formattedDimensionValues.toString() === stringifiedDimensionValues;
      });
      const matchingPriorRow = priorRows?.find((row) => {
        return row.formattedDimensionValues.toString() === stringifiedDimensionValues;
      });

      if (matchingCurrentRow) {
        const matchingCurrentRowMetricValues = this.parseMetricValues(matchingCurrentRow);
        const matchingPriorRowMetricValues = matchingPriorRow && this.parseMetricValues(matchingPriorRow);
        matchingCurrentRowMetricValues.forEach((value, idx) => {
          const metricArray = result[idx] || new Array<Array<Array<number | null>>>([], []);
          metricArray[0].push(value);
          if (this.comparePriorPeriod) {
            metricArray[1].push(matchingPriorRowMetricValues?.[idx] ?? null);
          }
          result[idx] = metricArray;
        });
      } else if (this.comparePriorPeriod && matchingPriorRow) {
        const matchingCurrentRowMetricValues = this.parseMetricValues(matchingPriorRow);
        matchingCurrentRowMetricValues.forEach((value, idx) => {
          const metricArray = result[idx] || new Array<Array<Array<number | null>>>([], []);
          metricArray[0].push(null);
          metricArray[1].push(value);
          result[idx] = metricArray;
        });
      }
    });

    return result;
  }
}
