import { useMemo } from 'react';

import { Dimensions, Metrics } from 'components/insights/constants';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import Histogram from 'components/shared/charts/Histogram';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import ChartContainer from '../ChartContainer';
import { generateExtendedChartConfig } from '../helpers/totalDischargesUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDetailHeader from '../MetricDetailHeader';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  onSelectedDimensionChange: (selectedDimension: Dimensions) => void;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimension: Dimensions;
};

const TotalDischargesChart = (props: Props) => {
  const { data, loading, onSelectedDimensionChange, rollups, selectedDimension } = props;

  const isMobile = useIsMobile();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.series.flat(), [data?.dimensions]);
  const dischargesCountRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.ID_COUNT, 0);
  const priorDischargesCountRollup = rollups.overall.metrics.getPriorDataPoint(Metrics.ID_COUNT, 0);
  const dischargesCountSeries = data.metrics.getSeries(Metrics.ID_COUNT);

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories, series: dischargesCountSeries }),
    [categories, dischargesCountSeries]
  );

  return (
    <MetricDetailContainer
      loading={loading}
      hasData={!!dischargesCountSeries[0]?.length}
      header={
        <>
          <MetricDetailHeader
            label='Total Discharges'
            loading={loading}
            hasData={!!dischargesCountSeries[0]?.length}
            value={
              <>
                {dischargesCountRollup ?? '—'}
                <MetricDiffBadge
                  currentValue={dischargesCountRollup}
                  priorValue={priorDischargesCountRollup}
                  comparator={Comparator.GREATER_THAN}
                  formatter={(val) => `${val.toFixed()}`}
                />
              </>
            }
          />
          {!isMobile && (
            <ViewBySelect
              selectedDimension={selectedDimension}
              additionalOptions={[
                {
                  label: 'Disposition',
                  value: Dimensions.REASON,
                },
              ]}
              groupType={selectedGroupType}
              onChange={(selectedOption) => {
                onSelectedDimensionChange(selectedOption.value);
              }}></ViewBySelect>
          )}
        </>
      }>
      <ChartContainer>
        <Histogram config={chartConfig} />
      </ChartContainer>
    </MetricDetailContainer>
  );
};

export default TotalDischargesChart;
