import { useState } from 'react';
import styled from 'styled-components';

import { CollapsibleContent } from 'components/Collapsible';
import { ExpandCollapse, SectionContainer } from 'components/Containers';
import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import Activity from 'models/Activity';
import { colors } from 'styles/theme/colors';
import { BodySmall } from 'styles/typography';

const getAnswerColor = (answer: { weight: string }) => {
  switch (answer.weight) {
    case 'positive':
      return colors.accentGreen;
    case 'negative':
      return colors.accentRed;
    default:
      return colors.black;
  }
};

export function QuestionsContent(props: { activity: Activity }) {
  const { activity } = props;
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <CollapsibleContent collapsed={!expanded}>
        <SectionContainer>
          <QuestionsContainer>
            {((activity.data.questions ?? []) as AnswerType[])
              .sort((a, b) => a.order - b.order)
              .map((question) => (
                <div key={activity.id + question.id}>
                  <div>
                    <ArchivableBodySmall $archived={activity.archived} color={colors.black75}>
                      {question.text}
                    </ArchivableBodySmall>
                  </div>
                  <div>
                    <ArchivableBodySmall $archived={activity.archived} color={getAnswerColor(question.answer)}>
                      {question.answer.text}
                    </ArchivableBodySmall>
                  </div>
                </div>
              ))}
          </QuestionsContainer>
        </SectionContainer>
      </CollapsibleContent>

      <SectionContainer>
        <ExpandCollapse onClick={() => setExpanded(!expanded)}>
          {expanded ? `Collapse ${activity.typeLabel}` : `Expand ${activity.typeLabel}`}
        </ExpandCollapse>
      </SectionContainer>
    </>
  );
}

const ArchivableBodySmall = styled(BodySmall)<{ $archived: boolean }>`
  text-decoration: ${({ $archived }) => ($archived ? 'line-through' : 'none')};
`;

const QuestionsContainer = styled.div`
  & > div {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }
`;
