import { useMemo } from 'react';
import styled from 'styled-components';

import { Dimensions, Metrics } from 'components/insights/constants';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import Histogram from 'components/shared/charts/Histogram';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import ChartContainer from '../ChartContainer';
import { generateExtendedChartConfig } from '../helpers/readmissionRateUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDetailHeader from '../MetricDetailHeader';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import ViewBySelect from '../ViewBySelect';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  onSelectedDimensionChange: (selectedDimension: Dimensions) => void;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimension: Dimensions;
};

const ReadmissionRateChart = (props: Props) => {
  const { data, loading, onSelectedDimensionChange, rollups, selectedDimension } = props;
  const isMobile = useIsMobile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.series.flat(), [data?.dimensions]);

  const readmissionsSumRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.READMISSIONS_SUM, 0);
  const readmissionRateRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.READMISSION_RATE, 0);
  const priorReadmissionRateRollup = rollups.overall.metrics.getPriorDataPoint(Metrics.READMISSION_RATE, 0);

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories, series: data.metrics }),
    [categories, data]
  );

  const formattedReadmissionRateRollup = readmissionRateRollup == null ? '—' : `${readmissionRateRollup.toFixed(1)}%`;

  return (
    <MetricDetailContainer
      loading={loading}
      hasData={!!categories.length}
      header={
        <>
          <MetricDetailHeader
            label='All Cause Readmission Rate'
            loading={loading}
            hasData={!!categories.length}
            value={
              <>
                {formattedReadmissionRateRollup}
                <MetricDiffBadge
                  currentValue={readmissionRateRollup}
                  priorValue={priorReadmissionRateRollup}
                  comparator={Comparator.LESS_THAN}
                  formatter={(val) => `${val.toFixed(1)}%`}
                />
              </>
            }
          />
          <Separator />
          <MetricDetailHeader
            label='All Cause Readmissions'
            loading={loading}
            value={readmissionsSumRollup ?? '—'}
            hasData={!!categories.length}
          />
          {!isMobile && (
            <ViewBySelect
              selectedDimension={selectedDimension}
              groupType={selectedGroupType}
              onChange={(selectedOption) => {
                onSelectedDimensionChange(selectedOption.value);
              }}></ViewBySelect>
          )}
        </>
      }>
      <ChartContainer>
        <Histogram config={chartConfig} />
      </ChartContainer>
    </MetricDetailContainer>
  );
};

export default ReadmissionRateChart;

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;
