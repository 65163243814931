import { useMemo } from 'react';

import { Metrics, Routes, Sources } from 'components/insights/constants';
import useAnalytics from 'components/insights/query/useAnalytics';
import FullGauge from 'components/shared/charts/FullGauge';
import colors from 'styles/theme/colors';

import MetricTile from './MetricTile';

export default function ReadmissionRateTile() {
  const queryParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DISCHARGES,
      metrics: [Metrics.ID_COUNT, Metrics.READMISSIONS_SUM, Metrics.READMISSION_RATE],
    }),
    []
  );

  const { customRef, loading, data } = useAnalytics(queryParams, { onIntersecting: true });

  const dischargesCount = data.metrics.getCurrentSeries(Metrics.ID_COUNT)[0] ?? -1;
  const readmissionsSum = data.metrics.getCurrentSeries(Metrics.READMISSIONS_SUM)[0] ?? -1;
  const readmissionRate = data.metrics.getCurrentSeries(Metrics.READMISSION_RATE)[0];

  const legend = [
    { name: 'Readmissions', value: readmissionsSum, color: '#DC2853' },
    { name: 'Discharges', value: dischargesCount, color: colors.black10 },
  ];

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsReadmissionRate'
      data-cy='insightsReadmissionRate'
      label='All Cause Readmission Rate'
      showValue={false}
      loading={loading}
      hasData={readmissionRate != null}
      navigateTo={Routes.READMISSION_RATE}>
      <FullGauge
        minValue={0}
        maxValue={dischargesCount}
        value={readmissionsSum}
        colorStops={[[0, '#DC2853']]}
        legend={legend}
        label={`${readmissionRate?.toFixed(1)}%`}
      />
    </MetricTile>
  );
}
