import { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import ActivitiesPerPatientWeekDetail from 'components/insights/layout/details/activitiesPerPatientWeek/ActivitiesPerPatientWeekDetail';
import Page from 'components/layouts/page/Page';
import FilterChips from 'components/shared/FilterChips';
import { FilterSection } from 'components/shared/filters/FilterSlideout';
import DesktopOnly from 'components/shared/responsiveness/DesktopOnly';
import { EscalationTypes } from 'models/Escalation';
import { useInsightsStore } from 'stores/insightsStore';

import InsightsFilterBar from './filterBar/InsightsFilterBar';
import AverageLengthOfStayDetail from './layout/details/averageLengthOfStay/AverageLengthOfStayDetail';
import OlioEngagementScoreDetail from './layout/details/olioEngagementScore/OlioEngagementScoreDetail';
import ReadmissionRateDetail from './layout/details/readmissionRate/ReadmissionRateDetail';
import ResponseTimeDetail from './layout/details/responseTime/ResponseTimeDetail';
import TotalDischargesDetail from './layout/details/totalDischarges/TotalDischargesDetail';
import { getFiltersForSelectedGroupType } from './layout/helpers/filters';
import MetricsDashboard from './layout/MetricsDashboard';
import { Routes as InsightsRoutes } from './constants';
import { useInsightsContext } from './InsightsContainer';

export default function InsightsPage() {
  const { profile } = useInsightsContext();
  const filters = useInsightsStore((state) => state.filters);
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const [filtersForSelectedGroupType, filterSections] = useMemo(
    () => getFiltersForSelectedGroupType(filters, selectedGroupType, profile.actingClient.clientType),
    [filters, selectedGroupType, profile.actingClient.clientType]
  );

  const chippableFilters = Object.entries(filtersForSelectedGroupType).reduce((acc, [filterKey, values]) => {
    if (Array.isArray(values) && values.length) {
      return { ...acc, [filterKey]: values };
    }
    return acc;
  }, {});

  return (
    <Page>
      <StyledPageHeader>
        <InsightsFilterBar
          filterSections={filterSections as FilterSection[]}
          activeFilters={filtersForSelectedGroupType}
        />
      </StyledPageHeader>
      <Page.SubHeader>
        <DesktopOnly>
          <FilterChips filters={chippableFilters} />
        </DesktopOnly>
      </Page.SubHeader>
      <Page.Content>
        <Routes>
          <Route path='/' element={<MetricsDashboard />} />
          <Route path={InsightsRoutes.OLIO_ENGAGEMENT_SCORE} element={<OlioEngagementScoreDetail />} />
          <Route path={InsightsRoutes.READMISSION_RATE} element={<ReadmissionRateDetail />} />
          <Route path={InsightsRoutes.AVERAGE_LENGTH_OF_STAY} element={<AverageLengthOfStayDetail />} />
          <Route path={InsightsRoutes.TOTAL_DISCHARGES} element={<TotalDischargesDetail />} />
          <Route path={InsightsRoutes.ACTIVITIES_PER_PATIENT_PER_WEEK} element={<ActivitiesPerPatientWeekDetail />} />
          <Route
            path={InsightsRoutes.PRIORITIES_NOTE_RESPONSE}
            element={<ResponseTimeDetail escalationType={EscalationTypes.PriorityNote} />}
          />
          <Route
            path={InsightsRoutes.ESCALATIONS_RESPONSE}
            element={<ResponseTimeDetail escalationType={EscalationTypes.Escalation} />}
          />
          <Route path='*' element={<Navigate to={InsightsRoutes.INSIGHTS_BASE} />} />
        </Routes>
      </Page.Content>
    </Page>
  );
}

const StyledPageHeader = styled(Page.Header)`
  background: none;
  border-bottom: none;
`;
