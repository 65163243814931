import styled from 'styled-components';

type Props = React.HTMLAttributes<HTMLDivElement>;

export default function MetricDetailControlBar(props: Props) {
  return <FlexContainer {...props} />;
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;
