import Highcharts, { SeriesOptionsType } from 'highcharts';

import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { formatDuration } from 'components/shared/charts/helpers';
import colors from 'styles/theme/colors';

// Calculates the scrollable width of the chart based on the number of data points
const calculateWidth = (pointWidth: number, dataLength: number) => {
  return (pointWidth + pointWidth * 1.5) * dataLength;
};

export const generateExtendedChartConfig = ({
  categories,
  data,
  rollups,
}: {
  categories: string[];
  data: AnalyticsResponseData;
  rollups: AnalyticsResponseRollupCollection;
}): Highcharts.Options => {
  const responseTimeRollup = rollups.overall.metrics.getCurrentDataPointByIndex(0, 0);
  const responseTimes = data.metrics.getCurrentSeriesByIndex(0);
  const priorResponseTimes = data.metrics.getPriorSeriesByIndex(0);

  return {
    chart: {
      scrollablePlotArea: {
        minWidth: calculateWidth(40, responseTimes?.length),
        scrollPositionX: 0,
      },
    },
    legend: {
      enabled: true,
      padding: 0,
      itemMarginBottom: 10,
      itemMarginTop: 16,
      align: 'center',
      symbolRadius: 2,
      x: 0,
      verticalAlign: 'bottom',
      y: 0,
      itemStyle: {
        fontSize: '12px',
      },
    },
    xAxis: {
      categories: categories,
      lineWidth: 1,
      lineColor: colors.black25,
      labels: {
        style: {
          fontSize: '10px',
          color: colors.black,
        },
      },
    },
    scrollbar: {
      enabled: true,
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineColor: colors.black10,
      tickAmount: 3,
      labels: {
        formatter: function (this) {
          return formatDuration(this.value);
        },
      },
      plotLines: [
        {
          color: '#F06532',
          width: 2,
          value: responseTimeRollup ?? 0,
          dashStyle: 'ShortDash',
        },
      ],
    },
    plotOptions: {
      column: {
        maxPointWidth: 40,
        events: { legendItemClick: () => false, mouseOver: () => false },
        dataLabels: {
          enabled: true,
          formatter: function (this) {
            return `<span style="font-size:10px;font-weight:normal;">${this.point.label}</span>`;
          },
        },
      },
      line: {
        enableMouseTracking: false,
        events: { legendItemClick: () => false, mouseOver: () => false },
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        type: 'column',
        data: responseTimes?.map((val) => ({ y: val ?? 0, label: val === null ? 'N/A' : formatDuration(val, true) })),
        name: 'Response Time',
        states: {
          inactive: {
            opacity: 0.05,
          },
        },
      },
      ...(priorResponseTimes?.length > 0
        ? [
            {
              type: 'column',
              data: priorResponseTimes?.map((val) => ({
                y: val ?? 0,
                label: val === null ? 'N/A' : formatDuration(val, true),
              })),
              name: 'Prior Response Time',
              color: '#C1CBFA',
            } as SeriesOptionsType,
          ]
        : []),
      {
        color: '#F06532',
        name: 'Average',
        type: 'line',
        dashStyle: 'ShortDash',
        data: [responseTimeRollup],
      },
    ],
  };
};
