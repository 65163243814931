import { useMemo } from 'react';
import styled from 'styled-components';

import { Dimensions, Metrics } from 'components/insights/constants';
import ChartContainer from 'components/insights/layout/details/ChartContainer';
import { generateExtendedChartConfig } from 'components/insights/layout/details/helpers/activitiesPerPatientPerWeekUtils';
import MetricDetailContainer from 'components/insights/layout/details/MetricDetailContainer';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import Histogram from 'components/shared/charts/Histogram';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  onSelectedDimensionChange: (selectedDimension: Dimensions) => void;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimension: Dimensions;
};

const ActivitiesPerPatientWeekChart = (props: Props) => {
  const { data, loading, onSelectedDimensionChange, rollups, selectedDimension } = props;

  const isMobile = useIsMobile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.series.flat(), [data.dimensions]);

  const activitiesPerPatientPerWeekRollup = rollups.overall.metrics.getCurrentDataPoint(
    Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK,
    0
  );
  const priorActivitiesPerPatientPerWeekRollup = rollups.overall.metrics.getPriorDataPoint(
    Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK,
    0
  );
  const locationEpisodeIdCountRollup = rollups.overall.metrics.getCurrentDataPoint(
    Metrics.LOCATION_EPISODE_ID_COUNT,
    0
  );
  const ownerActivitiesSumRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.OWNER_ACTIVITIES_SUM, 0);

  const activitiesPerPatientPerWeek = data.metrics.getCurrentSeries(Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK);

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories, data, rollups }),
    [categories, data, rollups]
  );

  return (
    <MetricDetailContainer
      loading={loading}
      hasData={!!activitiesPerPatientPerWeek?.length}
      header={
        <>
          <MetricDetailHeader
            label='Activities Per Patient Per Week'
            loading={loading}
            hasData={!!activitiesPerPatientPerWeek?.length}
            value={
              <>
                {`${activitiesPerPatientPerWeekRollup?.toFixed(1)}`}
                <MetricDiffBadge
                  currentValue={activitiesPerPatientPerWeekRollup}
                  priorValue={priorActivitiesPerPatientPerWeekRollup}
                  comparator={Comparator.GREATER_THAN}
                  formatter={(val) => `${val.toFixed(1)}`}
                />
              </>
            }
          />
          <Separator />
          <MetricDetailHeader
            label='Activities'
            hasData={!!activitiesPerPatientPerWeek?.length}
            loading={loading}
            value={ownerActivitiesSumRollup?.toString() ?? '0'}
          />
          <Separator />
          <MetricDetailHeader
            label='Patients'
            hasData={!!activitiesPerPatientPerWeek?.length}
            loading={loading}
            value={locationEpisodeIdCountRollup?.toString() ?? '0'}
          />
          {!isMobile && (
            <ViewBySelect
              selectedDimension={selectedDimension}
              groupType={selectedGroupType}
              onChange={(selectedOption) => {
                onSelectedDimensionChange(selectedOption.value);
              }}></ViewBySelect>
          )}
        </>
      }>
      <ChartContainer>
        <Histogram config={chartConfig} />
      </ChartContainer>
    </MetricDetailContainer>
  );
};

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;

export default ActivitiesPerPatientWeekChart;
