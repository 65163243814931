import styled from 'styled-components';

const Separator = styled.div<{ height?: string }>`
  height: ${({ height }) => height || '100%'};
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;

export default Separator;
